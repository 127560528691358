import { handleTokenExpiry, isTokenExpired } from "../utils/AuthUtil";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export const flightConfirmPrice = async (offer) => {
  try {
    const token = localStorage.getItem("Token");

    const headers = {
      "Content-Type": "application/json",
      "FlightDesitionTree-ID": localStorage.getItem("FlightDesitionTree-ID"),
    };

    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }
    const response = await fetch(`${SERVER_URL}/flight/confirm_price`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify({ offer }),
    });

    if (!response.ok) {
      throw new Error("Failed to get Flight Detail");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error("Error:", error);
  }
};

export const flightOrder = async (offer, traveler) => {
  if (isTokenExpired()) {
    handleTokenExpiry();
    return;
  }
  try {
    const response = await fetch(`${SERVER_URL}/flight/flight_order`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "FlightDesitionTree-ID": localStorage.getItem("FlightDesitionTree-ID"),
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
      body: JSON.stringify({ offer, traveler }),
    });

    if (!response.ok) {
      throw new Error("Failed to get Flight Detail");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error("Error:", error);
  }
};
