import React, { useState, useEffect, useRef } from "react";
import { RiMenu2Fill } from "react-icons/ri";
import Sidebar from "./Sidebar";
import { useNavigate } from "react-router-dom";
import { getSession } from "../services/SessionUp";
import { MdOpenInNew } from "react-icons/md";
import { ReactComponent as SulopaLogo } from "../assets/Sulopa_white_logo.svg";

const MenuBar = ({ clearMessages }) => {
  const [showSideBar, setShowSideBar] = useState(false);
  const sidebarRef = useRef(null);
  const navigate = useNavigate();

  const handleNewChatClick = () => {
    clearMessages();
    // sessionClear();
    getSession();
    // navigate("/");
  };

  const handleMain = () => {
    clearMessages();
    // sessionClear();
    getSession();
    navigate("/");
  };

  useEffect(() => {
    if(!localStorage.getItem("Token")){
      getSession();
    }
  },[])

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setShowSideBar(false);
    }
  };

  useEffect(() => {
    if (showSideBar) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showSideBar]);

  return (
    <div className="block md:hidden">
      <RiMenu2Fill
        className="absolute top-6 left-2"
        size={25}
        color="white"
        onClick={() => setShowSideBar(true)}
      />
      <div
        className={`fixed top-0 left-0 h-full bg-black bg-opacity-50 transition-all duration-300 ease-in-out ${
          showSideBar ? "w-full" : "w-0"
        }`}
      >
        {showSideBar && (
          <div
            ref={sidebarRef}
            className="bg-black h-full flex flex-col justify-between px-4 border-b-[1px] border-gray-200 w-[80%] pt-4"
          >
            <div className="flex flex-col items-center py-2">
              <div className="px-4" onClick={handleMain}>
                {/* <SulopaLogo className="w-52 h-14 cursor-pointer" /> */}
                <div className="text-FontSize2026 text-white text-center font-bold cursor-pointer">AITravelAssist</div>
                <div className="text-FontSize1020 text-white text-center ml-[80px] mt-[-7px] cursor-pointer">
              by Sulopa
            </div>
              </div>
              <div
                className="flex items-center justify-center text-[#fff] bg-[#212121] font-Primary py-2 px-2 rounded-lg cursor-pointer h-12 w-full text-center hover:bg-[#2F2F2F] mt-2"
                onClick={handleNewChatClick}
              >
                <span className="mr-2">New Chat</span>
                <MdOpenInNew size={20} color="#fff" className="ml-14" />
              </div>
            </div>

            <div className="flex justify-center items-center py-2 pb-8 px-4">
              <span className="text-white">
                Powered by{" "}
                <a
                  href="https://sulopa.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:underline"
                >
                  Sulopa
                </a>
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MenuBar;
