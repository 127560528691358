import { handleTokenExpiry, isTokenExpired } from "../utils/AuthUtil";
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export const addTraveller = async ( traveller ) => {
  // if (isTokenExpired()) {
  //   handleTokenExpiry();
  //   return;
  // }
  try {
    const response = await fetch(`${SERVER_URL}/travelers/add_travelers/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
      body: JSON.stringify( traveller ),
    });

    if (!response.ok) {
      throw new Error("Failed to Add Traveller");
    }

    const data = await response.json();

    return data;
  } catch (error) {
    throw new Error("Error:", error);
  }
};

export const getTravellers = async () => {
    // if (isTokenExpired()) {
    //   handleTokenExpiry();
    //   return;
    // }
    try {
      const response = await fetch(`${SERVER_URL}/travelers/get_travelers/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("Token")}`,
        },
      });
  
      if (!response.ok) {
        throw new Error("Failed to Get Traveller");
      }
  
      const data = await response.json();

      return data;
    } catch (error) {
      throw new Error("Error:", error);
    }
  };

  export const deleteTraveller = async (id) => {
    // if (isTokenExpired()) {
    //   handleTokenExpiry();
    //   return;
    // }
    try {
      const response = await fetch(`${SERVER_URL}/travelers/delete_traveler/${id}/`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("Token")}`,
        },
      });
  
      if (!response.ok) {
        throw new Error("Failed to Delete Traveller");
      }
  
      const data = await response.json();

      return data;
    } catch (error) {
      throw new Error("Error:", error);
    }
  };