import React, { useState, useRef, useEffect } from "react";
import Modal from "react-modal";
import { HotelData } from "../../DummyData";
import { MdClose } from "react-icons/md";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MdCalendarToday } from "react-icons/md";

const HotelBookingFilterModal = ({ hotel, isOpen, onClose, onSubmit }) => {
  const [adults, setAdults] = useState(1);
  const [checkInDate, setCheckInDate] = useState(null);
  const [checkOutDate, setCheckOutDate] = useState(null);
  const [boardType, setBoardType] = useState("ROOM_ONLY");

  const wrapperRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        onClose();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef, onClose]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const hotelDetails = HotelData.find(
      (item) => item.type === "hotel_details"
    );
    if (hotelDetails) {
      console.log("hotels details :", hotelDetails);
      onSubmit(hotelDetails.ai.data);
    }
    onClose();
  };

  return (
    <>
      {isOpen && (
        <div className="fixed top-0 left-0 w-full h-full bg-white opacity-50 z-40"></div>
      )}
      {isOpen && (
        <div
          ref={wrapperRef}
          className="fixed top-0 right-0 h-full md:p-20 p-10 md:w-[600px] w-full bg-[#212121] overflow-auto shadow-lg transform translate-x-0 transition-transform duration-300 ease-in-out z-50"
        >
          <div className="">
            <button
              type="button"
              onClick={onClose}
              className="absolute top-4 right-4"
            >
              <MdClose size={24} color="white" />
            </button>
            <h2 className="text-2xl mb-4 text-white">{hotel.name}</h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-white mt-6">Adults</label>
                <input
                  type="number"
                  min="1"
                  value={adults}
                  onChange={(e) => setAdults(e.target.value)}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-white">Check-in Date</label>
                <div className="relative w-full">
                  <DatePicker
                    selected={checkInDate}
                    onChange={(date) => setCheckInDate(date)}
                    minDate={new Date()}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="dd/mm/yyyy"
                    dateFormat="dd/MM/yyyy"
                    className="mt-1 block w-full cursor-pointer p-2 pr-64 border border-gray-300 rounded-md"
                    required
                  />
                  <div className="absolute inset-y-0 right-3 flex items-center pointer-events-none">
                    <MdCalendarToday className="text-gray-400" />
                  </div>
                </div>
              </div>

              <div className="mb-4">
                <label className="block text-white">Check-out Date</label>
                <div className="relative">
                  <DatePicker
                    selected={checkOutDate}
                    onChange={(date) => setCheckOutDate(date)}
                    minDate={checkInDate || new Date()}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="dd/mm/yyyy"
                    dateFormat="dd/MM/yyyy"
                    className="mt-1 block w-full cursor-pointer p-2 pr-64 border border-gray-300 rounded-md"
                    required
                  />
                  <div className="absolute inset-y-0 right-3 flex items-center pointer-events-none">
                    <MdCalendarToday className="text-gray-400" />
                  </div>
                </div>
              </div>
              <div className="mb-4">
                <label className="block text-white">Board Type</label>
                <select
                  value={boardType}
                  onChange={(e) => setBoardType(e.target.value)}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                  required
                >
                  <option value="ROOM_ONLY">Room Only</option>
                  <option value="BREAKFAST">Breakfast</option>
                  <option value="HALF_BOARD">Dinner & Breakfast</option>
                  <option value="FULL_BOARD">Full Board</option>
                  <option value="ALL_INCLUSIVE">All Inclusive</option>
                </select>
              </div>
              <div className="flex items-center mt-8">
                <button
                  type="submit"
                  className="w-full bg-[#2F2F2F] text-white py-2 rounded-lg hover:bg-[#676767] transition duration-300"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default HotelBookingFilterModal;
