import { handleTokenExpiry, isTokenExpired } from "../utils/AuthUtil";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export const signUp = async (value) => {
  try {
    const response = await fetch(`${SERVER_URL}/signup/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(value),
    });

    if (!response.ok) {
      throw new Error("Failed to Sign Up!");
    }

    const data = await response.json();
    if (data && data.access) {
      localStorage.setItem("Token", data.refresh);
      localStorage.setItem("TokenExpiry", Date.now() + 24 * 60 * 60 * 1000);
    }
    return data;
  } catch (error) {
    throw new Error("Error:", error);
  }
};

export const login = async (value) => {
    const response = await fetch(`${SERVER_URL}/login/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(value),
    });

    if (!response.ok) {
      if (response.status === 400){
        const data = response.json();
        return data;
      } else {
        throw new Error("Failed to Login!");
      }
    }

    const data = await response.json();
    if (data && data.access) {
      localStorage.setItem("Token", data.refresh);
      localStorage.setItem("TokenExpiry", Date.now() + 24 * 60 * 60 * 1000);
    }
    return data;
};

export const logout = async () => {
  if (isTokenExpired()) {
    handleTokenExpiry();
    return;
  }
  try {
    const response = await fetch(`${SERVER_URL}/logout/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to LogOut!");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error("Error:", error);
  }
};
