export const HotelData = [
  {
    "human": "Hi",
    "ai": "Hello, I am a hotel booking chatbot assistant. How can I help you with booking a hotel room today?",
    "type": "message"
  },
  {
    "human": "book a hotel",
    "ai": {
      "text": "Could you please provide the details of the place you want to stay?",
      "options": ["Paris", "New York", "Delhi", "Kolkata"],
    },
    "type": "location_message"
  },
  {
    "human": "I need to book a hotel room.",
    "ai": {
      "text": "Could you please provide the details of the place you want to stay?",
      "options": ["Paris", "New York", "Delhi", "Kolkata"],
    },
    "type": "location_message"
  },
  {
    "human": "Paris",
    "ai": {
      "text": "Can you choose the amenities from the list below:",
      "options": ["SWIMMING POOL", "FITNESS CENTER", "AIR CONDITIONING", "PARKING", "MEETING ROOMS", "WIFI"],
    },
    "type": "amenities_message"
  },  
  {
    "human": "New York",
    "ai": {
      "text": "Can you choose the amenities from the list below:",
      "options": ["SWIMMING POOL", "FITNESS CENTER", "AIR CONDITIONING", "PARKING", "MEETING ROOMS", "WIFI"],
    },
    "type": "amenities_message"
  },
  {
    "human": "Delhi",
    "ai": {
      "text": "Can you choose the amenities from the list below:",
      "options": ["SWIMMING POOL", "FITNESS CENTER", "AIR CONDITIONING", "PARKING", "MEETING ROOMS", "WIFI"],
    },
        "type": "amenities_message"
  },
  {
    "human": "Kolkata",
    "ai": {
      "text": "Can you choose the amenities from the list below:",
      "options": ["SWIMMING POOL", "FITNESS CENTER", "AIR CONDITIONING", "PARKING", "MEETING ROOMS", "WIFI"],
    },
    "type": "amenities_message"
  },
  {
    "human": "SWIMMING POOL",
    "ai": {
      "text": "What rating are you expecting?",
      "options": ["3 Star", "4 Star", "5 Star"],
    },
    "type": "rating_message"
  },
  {
    "human": "FITNESS CENTER",
    "ai": {
      "text": "What rating are you expecting?",
      "options": ["3 Star", "4 Star", "5 Star"],
    },
    "type": "rating_message"
  },
  {
    "human": "AIR CONDITIONING",
    "ai": {
      "text": "What rating are you expecting?",
      "options": ["3 Star", "4 Star", "5 Star"],
    },
    "type": "rating_message"
  },
  {
    "human": "MEETING ROOMS",
    "ai": {
      "text": "What rating are you expecting?",
      "options": ["3 Star", "4 Star", "5 Star"],
    },
    "type": "rating_message"
  },
  {
    "human": "AIR CONDITIONING",
    "ai": {
      "text": "What rating are you expecting?",
      "options": ["3 Star", "4 Star", "5 Star"],
    },
    "type": "rating_message"
  },
  {
    "human": "WIFI",
    "ai": {
      "text": "What rating are you expecting?",
      "options": ["3 Star", "4 Star", "5 Star"],
    },
    "type": "rating_message"
  },
  {
    "human": "3 Star",
    "ai": {
      "data": [
        {
          "chainCode": "YX",
          "iataCode": "PAR",
          "dupeId": 700054607,
          "name": "HOTEL LES RIVES DE NOTRE DAME",
          "hotelId": "YXPARRND",
          "geoCode": {
            "latitude": 48.85327,
            "longitude": 2.34574
          },
          "address": {
            "countryCode": "FR"
          },
          "distance": {
            "value": 0.23,
            "unit": "KM"
          },
          "rating": 3.6,
          "price": 340,
          "taxes": "189 per night",
          "lastUpdate": "2023-06-15T10:15:27"
        },
        {
          "chainCode": "HN",
          "iataCode": "PAR",
          "dupeId": 502345064,
          "name": "HN TEST PROPERTY1 FOR E2E TESTING",
          "hotelId": "HNPARKGU",
          "geoCode": {
            "latitude": 48.85315,
            "longitude": 2.34513
          },
          "address": {
            "countryCode": "FR"
          },
          "distance": {
            "value": 0.27,
            "unit": "KM"
          },
          "rating": 3.1,
          "price": 270,
          "taxes": "90 per night",
          "lastUpdate": "2024-05-27T07:58:55"
        },
        {
          "chainCode": "HN",
          "iataCode": "PAR",
          "dupeId": 502345065,
          "name": "TEST PROPERTY FOR API ACTIVATE/DE-ACTIVA",
          "hotelId": "HNPARNUJ",
          "geoCode": {
            "latitude": 48.85315,
            "longitude": 2.34513
          },
          "address": {
            "countryCode": "FR"
          },
          "distance": {
            "value": 0.27,
            "unit": "KM"
          },
          "rating": 3.7,
          "price": 280,
          "taxes": "161 per night",
          "lastUpdate": "2024-05-27T07:59:57"
        },
        {
          "chainCode": "HN",
          "iataCode": "PAR",
          "dupeId": 502345066,
          "name": "TEST PROPERTY FOR LSS PHASE 1",
          "hotelId": "HNPARSPC",
          "geoCode": {
            "latitude": 48.85315,
            "longitude": 2.34513
          },
          "address": {
            "countryCode": "IN"
          },
          "distance": {
            "value": 0.27,
            "unit": "KM"
          },
          "rating": 2.9,
          "price": 890,
          "taxes": "109 per night",
          "lastUpdate": "2024-05-29T09:51:07"
        },
        {
          "chainCode": "BW",
          "iataCode": "PAR",
          "dupeId": 700054608,
          "name": "BW PREMIER ROYAL SAINT MICHEL",
          "hotelId": "BWPAR599",
          "geoCode": {
            "latitude": 48.85269,
            "longitude": 2.34384
          },
          "address": {
            "countryCode": "FR"
          },
          "distance": {
            "value": 0.37,
            "unit": "KM"
          },
          "rating": 3.5,
          "price": 400,
          "taxes": "59 per night",
          "lastUpdate": "2023-06-15T09:58:55"
        },
        {
          "chainCode": "BW",
          "iataCode": "PAR",
          "dupeId": 700045082,
          "name": "BEST WESTERN JARDIN DE CLUNY",
          "hotelId": "BWPAR196",
          "geoCode": {
            "latitude": 48.84985000000001,
            "longitude": 2.34699
          },
          "address": {
            "countryCode": "FR"
          },
          "distance": {
            "value": 0.41,
            "unit": "KM"
          },
          "rating": 3.6,
          "price": 330,
          "taxes": "99 per night",
          "lastUpdate": "2023-06-15T09:55:46"
        }
      ],
    },
    "type" : "hotel_list"
  },
  {
    "human": "4 Star",
    "ai": {
      "data": [
        {
          "chainCode": "YX",
          "iataCode": "PAR",
          "dupeId": 700054607,
          "name": "HOTEL LES RIVES DE NOTRE DAME",
          "hotelId": "YXPARRND",
          "geoCode": {
            "latitude": 48.85327,
            "longitude": 2.34574
          },
          "address": {
            "countryCode": "FR"
          },
          "distance": {
            "value": 0.23,
            "unit": "KM"
          },
          "rating": 4.6,
          "price": 340,
          "taxes": "189 per night",
          "lastUpdate": "2023-06-15T10:15:27"
        },
        {
          "chainCode": "HN",
          "iataCode": "PAR",
          "dupeId": 502345064,
          "name": "HN TEST PROPERTY1 FOR E2E TESTING",
          "hotelId": "HNPARKGU",
          "geoCode": {
            "latitude": 48.85315,
            "longitude": 2.34513
          },
          "address": {
            "countryCode": "FR"
          },
          "distance": {
            "value": 0.27,
            "unit": "KM"
          },
          "rating": 4.1,
          "price": 270,
          "taxes": "59 per night",
          "lastUpdate": "2024-05-27T07:58:55"
        },
        {
          "chainCode": "HN",
          "iataCode": "PAR",
          "dupeId": 502345065,
          "name": "TEST PROPERTY FOR API ACTIVATE/DE-ACTIVA",
          "hotelId": "HNPARNUJ",
          "geoCode": {
            "latitude": 48.85315,
            "longitude": 2.34513
          },
          "address": {
            "countryCode": "FR"
          },
          "distance": {
            "value": 0.27,
            "unit": "KM"
          },
          "rating": 4.7,
          "price": 280,
          "taxes": "77 per night",
          "lastUpdate": "2024-05-27T07:59:57"
        },
        {
          "chainCode": "HN",
          "iataCode": "PAR",
          "dupeId": 502345066,
          "name": "TEST PROPERTY FOR LSS PHASE 1",
          "hotelId": "HNPARSPC",
          "geoCode": {
            "latitude": 48.85315,
            "longitude": 2.34513
          },
          "address": {
            "countryCode": "IN"
          },
          "distance": {
            "value": 0.27,
            "unit": "KM"
          },
          "rating": 4.9,
          "price": 890,
          "taxes": "99 per night",
          "lastUpdate": "2024-05-29T09:51:07"
        },
        {
          "chainCode": "BW",
          "iataCode": "PAR",
          "dupeId": 700054608,
          "name": "BW PREMIER ROYAL SAINT MICHEL",
          "hotelId": "BWPAR599",
          "geoCode": {
            "latitude": 48.85269,
            "longitude": 2.34384
          },
          "address": {
            "countryCode": "FR"
          },
          "distance": {
            "value": 0.37,
            "unit": "KM"
          },
          "rating": 4.5,
          "price": 400,
          "taxes": "101 per night",
          "lastUpdate": "2023-06-15T09:58:55"
        },
        {
          "chainCode": "BW",
          "iataCode": "PAR",
          "dupeId": 700045082,
          "name": "BEST WESTERN JARDIN DE CLUNY",
          "hotelId": "BWPAR196",
          "geoCode": {
            "latitude": 48.84985000000001,
            "longitude": 2.34699
          },
          "address": {
            "countryCode": "FR"
          },
          "distance": {
            "value": 0.41,
            "unit": "KM"
          },
          "rating": 4.6,
          "price": 330,
          "taxes": "79 per night",
          "lastUpdate": "2023-06-15T09:55:46"
        }
      ],
    },
    "type" : "hotel_list"
  },
  {
    "human": "5 Star",
    "ai": {
      "data": [
        {
          "chainCode": "YX",
          "iataCode": "PAR",
          "dupeId": 700054607,
          "name": "HOTEL LES RIVES DE NOTRE DAME",
          "hotelId": "YXPARRND",
          "geoCode": {
            "latitude": 48.85327,
            "longitude": 2.34574
          },
          "address": {
            "countryCode": "FR"
          },
          "distance": {
            "value": 0.23,
            "unit": "KM"
          },
          "rating": 4.6,
          "price": 340,
          "taxes": "189 per night",
          "lastUpdate": "2023-06-15T10:15:27"
        },
        {
          "chainCode": "HN",
          "iataCode": "PAR",
          "dupeId": 502345064,
          "name": "HN TEST PROPERTY1 FOR E2E TESTING",
          "hotelId": "HNPARKGU",
          "geoCode": {
            "latitude": 48.85315,
            "longitude": 2.34513
          },
          "address": {
            "countryCode": "FR"
          },
          "distance": {
            "value": 0.27,
            "unit": "KM"
          },
          "rating": 4.9,
          "price": 270,
          "taxes": "79 per night",
          "lastUpdate": "2024-05-27T07:58:55"
        },
        {
          "chainCode": "HN",
          "iataCode": "PAR",
          "dupeId": 502345065,
          "name": "TEST PROPERTY FOR API ACTIVATE/DE-ACTIVA",
          "hotelId": "HNPARNUJ",
          "geoCode": {
            "latitude": 48.85315,
            "longitude": 2.34513
          },
          "address": {
            "countryCode": "FR"
          },
          "distance": {
            "value": 0.27,
            "unit": "KM"
          },
          "rating": 4.7,
          "price": 280,
          "taxes": "89 per night",
          "lastUpdate": "2024-05-27T07:59:57"
        },
        {
          "chainCode": "HN",
          "iataCode": "PAR",
          "dupeId": 502345066,
          "name": "TEST PROPERTY FOR LSS PHASE 1",
          "hotelId": "HNPARSPC",
          "geoCode": {
            "latitude": 48.85315,
            "longitude": 2.34513
          },
          "address": {
            "countryCode": "IN"
          },
          "distance": {
            "value": 0.27,
            "unit": "KM"
          },
          "rating": 4.9,
          "price": 890,
          "taxes": "122 per night",
          "lastUpdate": "2024-05-29T09:51:07"
        },
        {
          "chainCode": "BW",
          "iataCode": "PAR",
          "dupeId": 700054608,
          "name": "BW PREMIER ROYAL SAINT MICHEL",
          "hotelId": "BWPAR599",
          "geoCode": {
            "latitude": 48.85269,
            "longitude": 2.34384
          },
          "address": {
            "countryCode": "FR"
          },
          "distance": {
            "value": 0.37,
            "unit": "KM"
          },
          "rating": 4.5,
          "price": 400,
          "taxes": "69 per night",
          "lastUpdate": "2023-06-15T09:58:55"
        },
        {
          "chainCode": "BW",
          "iataCode": "PAR",
          "dupeId": 700045082,
          "name": "BEST WESTERN JARDIN DE CLUNY",
          "hotelId": "BWPAR196",
          "geoCode": {
            "latitude": 48.84985000000001,
            "longitude": 2.34699
          },
          "address": {
            "countryCode": "FR"
          },
          "distance": {
            "value": 0.41,
            "unit": "KM"
          },
          "rating": 4.6,
          "price": 330,
          "taxes": "101 per night",
          "lastUpdate": "2023-06-15T09:55:46"
        }
      ],
    },
    "type" : "hotel_list"
  },
  {
    "human": "Bengaluru",
    "ai": {
      "data": [
        {
          "chainCode": "YX",
          "iataCode": "PAR",
          "dupeId": 700054607,
          "name": "HOTEL LES RIVES DE NOTRE DAME",
          "hotelId": "YXPARRND",
          "geoCode": {
            "latitude": 48.85327,
            "longitude": 2.34574
          },
          "address": {
            "countryCode": "FR"
          },
          "distance": {
            "value": 0.23,
            "unit": "KM"
          },
          "rating": 4.6,
          "price": 340,
          "taxes": "189 per night",
          "lastUpdate": "2023-06-15T10:15:27"
        },
        {
          "chainCode": "HN",
          "iataCode": "PAR",
          "dupeId": 502345064,
          "name": "HN TEST PROPERTY1 FOR E2E TESTING",
          "hotelId": "HNPARKGU",
          "geoCode": {
            "latitude": 48.85315,
            "longitude": 2.34513
          },
          "address": {
            "countryCode": "FR"
          },
          "distance": {
            "value": 0.27,
            "unit": "KM"
          },
          "rating": 4.9,
          "price": 270,
          "taxes": "79 per night",
          "lastUpdate": "2024-05-27T07:58:55"
        },
        {
          "chainCode": "HN",
          "iataCode": "PAR",
          "dupeId": 502345065,
          "name": "TEST PROPERTY FOR API ACTIVATE/DE-ACTIVA",
          "hotelId": "HNPARNUJ",
          "geoCode": {
            "latitude": 48.85315,
            "longitude": 2.34513
          },
          "address": {
            "countryCode": "FR"
          },
          "distance": {
            "value": 0.27,
            "unit": "KM"
          },
          "rating": 4.7,
          "price": 280,
          "taxes": "89 per night",
          "lastUpdate": "2024-05-27T07:59:57"
        },
        {
          "chainCode": "HN",
          "iataCode": "PAR",
          "dupeId": 502345066,
          "name": "TEST PROPERTY FOR LSS PHASE 1",
          "hotelId": "HNPARSPC",
          "geoCode": {
            "latitude": 48.85315,
            "longitude": 2.34513
          },
          "address": {
            "countryCode": "IN"
          },
          "distance": {
            "value": 0.27,
            "unit": "KM"
          },
          "rating": 4.9,
          "price": 890,
          "taxes": "122 per night",
          "lastUpdate": "2024-05-29T09:51:07"
        },
        {
          "chainCode": "BW",
          "iataCode": "PAR",
          "dupeId": 700054608,
          "name": "BW PREMIER ROYAL SAINT MICHEL",
          "hotelId": "BWPAR599",
          "geoCode": {
            "latitude": 48.85269,
            "longitude": 2.34384
          },
          "address": {
            "countryCode": "FR"
          },
          "distance": {
            "value": 0.37,
            "unit": "KM"
          },
          "rating": 4.5,
          "price": 400,
          "taxes": "69 per night",
          "lastUpdate": "2023-06-15T09:58:55"
        },
        {
          "chainCode": "BW",
          "iataCode": "PAR",
          "dupeId": 700045082,
          "name": "BEST WESTERN JARDIN DE CLUNY",
          "hotelId": "BWPAR196",
          "geoCode": {
            "latitude": 48.84985000000001,
            "longitude": 2.34699
          },
          "address": {
            "countryCode": "FR"
          },
          "distance": {
            "value": 0.41,
            "unit": "KM"
          },
          "rating": 4.6,
          "price": 330,
          "taxes": "101 per night",
          "lastUpdate": "2023-06-15T09:55:46"
        }
      ],
    },
    "type" : "hotel_list"
  },
  {
    "human": "Puri",
    "ai": {
      "data": [
        {
          "chainCode": "YX",
          "iataCode": "PAR",
          "dupeId": 700054607,
          "name": "HOTEL LES RIVES DE NOTRE DAME",
          "hotelId": "YXPARRND",
          "geoCode": {
            "latitude": 48.85327,
            "longitude": 2.34574
          },
          "address": {
            "countryCode": "FR"
          },
          "distance": {
            "value": 0.23,
            "unit": "KM"
          },
          "rating": 4.6,
          "price": 340,
          "taxes": "189 per night",
          "lastUpdate": "2023-06-15T10:15:27"
        },
        {
          "chainCode": "HN",
          "iataCode": "PAR",
          "dupeId": 502345064,
          "name": "HN TEST PROPERTY1 FOR E2E TESTING",
          "hotelId": "HNPARKGU",
          "geoCode": {
            "latitude": 48.85315,
            "longitude": 2.34513
          },
          "address": {
            "countryCode": "FR"
          },
          "distance": {
            "value": 0.27,
            "unit": "KM"
          },
          "rating": 4.9,
          "price": 270,
          "taxes": "79 per night",
          "lastUpdate": "2024-05-27T07:58:55"
        },
        {
          "chainCode": "HN",
          "iataCode": "PAR",
          "dupeId": 502345065,
          "name": "TEST PROPERTY FOR API ACTIVATE/DE-ACTIVA",
          "hotelId": "HNPARNUJ",
          "geoCode": {
            "latitude": 48.85315,
            "longitude": 2.34513
          },
          "address": {
            "countryCode": "FR"
          },
          "distance": {
            "value": 0.27,
            "unit": "KM"
          },
          "rating": 4.7,
          "price": 280,
          "taxes": "89 per night",
          "lastUpdate": "2024-05-27T07:59:57"
        },
        {
          "chainCode": "HN",
          "iataCode": "PAR",
          "dupeId": 502345066,
          "name": "TEST PROPERTY FOR LSS PHASE 1",
          "hotelId": "HNPARSPC",
          "geoCode": {
            "latitude": 48.85315,
            "longitude": 2.34513
          },
          "address": {
            "countryCode": "IN"
          },
          "distance": {
            "value": 0.27,
            "unit": "KM"
          },
          "rating": 4.9,
          "price": 890,
          "taxes": "122 per night",
          "lastUpdate": "2024-05-29T09:51:07"
        },
        {
          "chainCode": "BW",
          "iataCode": "PAR",
          "dupeId": 700054608,
          "name": "BW PREMIER ROYAL SAINT MICHEL",
          "hotelId": "BWPAR599",
          "geoCode": {
            "latitude": 48.85269,
            "longitude": 2.34384
          },
          "address": {
            "countryCode": "FR"
          },
          "distance": {
            "value": 0.37,
            "unit": "KM"
          },
          "rating": 4.5,
          "price": 400,
          "taxes": "69 per night",
          "lastUpdate": "2023-06-15T09:58:55"
        },
        {
          "chainCode": "BW",
          "iataCode": "PAR",
          "dupeId": 700045082,
          "name": "BEST WESTERN JARDIN DE CLUNY",
          "hotelId": "BWPAR196",
          "geoCode": {
            "latitude": 48.84985000000001,
            "longitude": 2.34699
          },
          "address": {
            "countryCode": "FR"
          },
          "distance": {
            "value": 0.41,
            "unit": "KM"
          },
          "rating": 4.6,
          "price": 330,
          "taxes": "101 per night",
          "lastUpdate": "2023-06-15T09:55:46"
        }
      ],
    },
    "type" : "hotel_list"
  },
  {
    "human": "Kashmir",
    "ai": {
      "data": [
        {
          "chainCode": "YX",
          "iataCode": "PAR",
          "dupeId": 700054607,
          "name": "HOTEL LES RIVES DE NOTRE DAME",
          "hotelId": "YXPARRND",
          "geoCode": {
            "latitude": 48.85327,
            "longitude": 2.34574
          },
          "address": {
            "countryCode": "FR"
          },
          "distance": {
            "value": 0.23,
            "unit": "KM"
          },
          "rating": 4.6,
          "price": 340,
          "taxes": "189 per night",
          "lastUpdate": "2023-06-15T10:15:27"
        },
        {
          "chainCode": "HN",
          "iataCode": "PAR",
          "dupeId": 502345064,
          "name": "HN TEST PROPERTY1 FOR E2E TESTING",
          "hotelId": "HNPARKGU",
          "geoCode": {
            "latitude": 48.85315,
            "longitude": 2.34513
          },
          "address": {
            "countryCode": "FR"
          },
          "distance": {
            "value": 0.27,
            "unit": "KM"
          },
          "rating": 4.9,
          "price": 270,
          "taxes": "79 per night",
          "lastUpdate": "2024-05-27T07:58:55"
        },
        {
          "chainCode": "HN",
          "iataCode": "PAR",
          "dupeId": 502345065,
          "name": "TEST PROPERTY FOR API ACTIVATE/DE-ACTIVA",
          "hotelId": "HNPARNUJ",
          "geoCode": {
            "latitude": 48.85315,
            "longitude": 2.34513
          },
          "address": {
            "countryCode": "FR"
          },
          "distance": {
            "value": 0.27,
            "unit": "KM"
          },
          "rating": 4.7,
          "price": 280,
          "taxes": "89 per night",
          "lastUpdate": "2024-05-27T07:59:57"
        },
        {
          "chainCode": "HN",
          "iataCode": "PAR",
          "dupeId": 502345066,
          "name": "TEST PROPERTY FOR LSS PHASE 1",
          "hotelId": "HNPARSPC",
          "geoCode": {
            "latitude": 48.85315,
            "longitude": 2.34513
          },
          "address": {
            "countryCode": "IN"
          },
          "distance": {
            "value": 0.27,
            "unit": "KM"
          },
          "rating": 4.9,
          "price": 890,
          "taxes": "122 per night",
          "lastUpdate": "2024-05-29T09:51:07"
        },
        {
          "chainCode": "BW",
          "iataCode": "PAR",
          "dupeId": 700054608,
          "name": "BW PREMIER ROYAL SAINT MICHEL",
          "hotelId": "BWPAR599",
          "geoCode": {
            "latitude": 48.85269,
            "longitude": 2.34384
          },
          "address": {
            "countryCode": "FR"
          },
          "distance": {
            "value": 0.37,
            "unit": "KM"
          },
          "rating": 4.5,
          "price": 400,
          "taxes": "69 per night",
          "lastUpdate": "2023-06-15T09:58:55"
        },
        {
          "chainCode": "BW",
          "iataCode": "PAR",
          "dupeId": 700045082,
          "name": "BEST WESTERN JARDIN DE CLUNY",
          "hotelId": "BWPAR196",
          "geoCode": {
            "latitude": 48.84985000000001,
            "longitude": 2.34699
          },
          "address": {
            "countryCode": "FR"
          },
          "distance": {
            "value": 0.41,
            "unit": "KM"
          },
          "rating": 4.6,
          "price": 330,
          "taxes": "101 per night",
          "lastUpdate": "2023-06-15T09:55:46"
        }
      ],
    },
    "type" : "hotel_list"
  },
  {
    "human": "Send me details of this",
    "ai": {
      "data": [
        {
          "type": "hotel-offers",
          "hotel": {
            "type": "hotel",
            "hotelId": "MCLONGHM",
            "chainCode": "MC",
            "dupeId": "700031300",
            "name": "JW Marriott Grosvenor House London",
            "cityCode": "LON",
            "latitude": 51.50988,
            "longitude": -0.15509
          },
          "available": true,
          "offers": [
            {
              "id": "ADN27W2CKJ",
              "checkInDate": "2024-06-09",
              "checkOutDate": "2024-06-10",
              "rateCode": "RAC",
              "rateFamilyEstimated": {
                "code": "PRO",
                "type": "P"
              },
              "room": {
                "type": "AP7",
                "typeEstimated": {
                  "category": "FAMILY_ROOM",
                  "beds": 2,
                  "bedType": "DOUBLE"
                },
                "description": {
                  "text": "Prepay Non-refundable Non-changeable, prepay in full\nFamily Room, 2 Doubles,\n37sqm/398sqft-42sqm/452sqft, Wireless",
                  "lang": "EN"
                }
              },
              "guests": {
                "adults": 1
              },
              "price": {
                "currency": "GBP",
                "base": "560.00",
                "total": "588.00",
                "variations": {
                  "average": {
                    "base": "560.00"
                  },
                  "changes": [
                    {
                      "startDate": "2024-11-22",
                      "endDate": "2024-11-23",
                      "base": "560.00"
                    }
                  ]
                }
              },
              "policies": {
                "cancellations": [
                  {
                    "description": {
                      "text": "NON-REFUNDABLE RATE"
                    },
                    "type": "FULL_STAY"
                  }
                ],
                "paymentType": "deposit"
              },
              "self": "https://test.api.amadeus.com/v3/shopping/hotel-offers/ADN27W2CKJ"
            }
          ],
          "self": "https://test.api.amadeus.com/v3/shopping/hotel-offers?hotelIds=MCLONGHM&adults=1&checkInDate=2024-11-22&paymentPolicy=NONE&roomQuantity=1"
        }
      ]
    },
    "type" : "hotel_details"
  },
  {
    "human": "Book hotel",
    "ai": {
      "data": {
        "hotel": "The OM Resort",
        "type": "string",
        "checkInDate": "2024-11-22",
        "checkOutDate": "2024-11-23",
        "guests": [
          {
            "tid": 0,
            "frequentTraveler": [
              {
                "airlineCode": "AF",
                "frequentTravelerId": "32546971326"
              }
            ],
            "phone": "6792784168",
            "email": "test.test@test.com",
            "title": "MR",
            "firstName": "Angad",
            "lastName": "Barik",
            "childAge": 0,
            "isAdult": true
          }
        ],
        "travelAgent": {
          "contact": {
            "email": "travelAgent@agency.com",
            "fax": "+33985632145",
            "phone": "+33679278416"
          },
          "travelAgentId": "string"
        }}},
    "type": "hotel_booking"
  }
];
