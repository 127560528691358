import React, { useContext, useEffect, useRef, useState } from "react";
import { FlightContext } from "../../utils/FlightContext";
import { deleteTraveller, getTravellers } from "../../services/Travellers";
import { FaSpinner, FaTrashAlt } from "react-icons/fa";
import { flightOrder } from "../../services/flight";
import { MdClose } from "react-icons/md";

const SavedTravellers = ({ addAIMessage, addMsgType }) => {
  const {
    isAddTravelerModalOpen,
    setIsAddTravelerModalOpen,
    isSavedTravellerModalOpen,
    setIsSavedTravellerModalOpen,
    flightOffer,
    setFlightOrderData,
    travellers,
    setTravellers,
    setShowSkeleton,
    setShowLogin,
  } = useContext(FlightContext);

  const [selectedTravellers, setSelectedTravellers] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const drawerRef = useRef(null);

  useEffect(() => {
    const fetchTravellers = async () => {
      try {
        const response = await getTravellers();
        if (response && Array.isArray(response.Travelers)) {
          setTravellers(response.Travelers);
        } else {
          console.error(
            "API response does not contain a valid 'Travelers' array: ",
            response
          );
        }
      } catch (error) {
        console.error("Error fetching travellers: ", error);
      }
    };

    fetchTravellers();
  }, []);

  const handleCheckboxChange = (travellerId) => {
    setSelectedTravellers((prevSelected) => {
      if (prevSelected.includes(travellerId)) {
        return prevSelected.filter((id) => id !== travellerId);
      } else {
        return [...prevSelected, travellerId];
      }
    });
  };

  // const handleDeleteTraveller = async (travellerId) => {
  //   try {
  //     const response = await deleteTraveller(travellerId);
  //     if (response.result === "Success") {
  //       setTravellers((prevTravellers) =>
  //         prevTravellers.filter((traveller) => traveller.id !== travellerId)
  //       );
  //       setSelectedTravellers((prevSelected) =>
  //         prevSelected.filter((id) => id !== travellerId)
  //       );
  //     }
  //   } catch (error) {
  //     console.error("Error: ", error);
  //   }
  // };
  const handleDeleteTraveller = async (travellerId) => {
    try {
      const response = await deleteTraveller(travellerId);

      console.log("Delete response:", response);

      if (response.result === "Success" || response.status === 200) {
        setTravellers((prevTravellers) =>
          prevTravellers.filter((traveller) => traveller.id !== travellerId)
        );

        setSelectedTravellers((prevSelected) =>
          prevSelected.filter((id) => id !== travellerId)
        );
      } else {
        console.error("Failed to delete traveller. Response:", response);
      }
    } catch (error) {
      console.error("Error deleting traveller:", error);
    }
  };

  const handleConfirm = async () => {
    setLoading(true);
    setShowSkeleton(true);
    if (selectedTravellers.length === 0) {
      setErrorMessage("Please select at least one traveller.");
      return;
    }

    const selectedTravellerDetails = travellers
      .filter((traveller) => selectedTravellers.includes(traveller.id))
      .map((traveller, index) => ({
        first_name: traveller.first_name,
        last_name: traveller.last_name,
        date_of_birth: traveller.date_of_birth,
        gender: traveller.gender,
        email: traveller.email,
        phone_number: traveller.phone_number,
        country_code: traveller.country_code,
        passport_number: traveller.passport_number || "",
        birth_place: traveller.birth_place || "",
        issuance_location: traveller.issuance_location || "",
        issuance_date: traveller.issuance_date || "",
        expiry_date: traveller.expiry_date || "",
        issuance_country: traveller.issuance_country || "",
        validity_country: traveller.validity_country || "",
        nationality_country: traveller.nationality_country || "",
        holder: traveller.holder || "",
        existing_traveller: true,
        id: index + 1,
      }));

    const docReq = localStorage.getItem("docReq");
    const payload = {
      no_of_travelers: selectedTravellerDetails.length,
      document_required: localStorage.getItem("docReq"),
      details: selectedTravellerDetails,
    };

    console.log("Payload to send:", JSON.stringify(payload, null, 2));
    setShowSkeleton(true);
    try {
      setIsSavedTravellerModalOpen(false);
      const response = await flightOrder(flightOffer, payload);
      if (response) {
        setShowSkeleton(false);
      }
      console.log("Response from flight order: ", response);
      setShowSkeleton(true);
      addAIMessage({
        message: response.message.message,
        category: "AI_FlightOrder",
      });
      addMsgType(response.message.message.type);
      setFlightOrderData(response.message.message, "AI");
      // handleTicket();
    } catch (error) {
      setShowSkeleton(false);
      console.error("Error: ", error);
    }
    setLoading(false);
    setShowSkeleton(false);
  };

  const handleClose = () => {
    setIsSavedTravellerModalOpen(false);
    // setTimeout(() => navigate("/"), 300); // Delay navigation to allow animation to complete
  };

  // useEffect(() => {
  //   setIsSavedTravellerModalOpen(true);
  // },[])

  console.log("travellers list", travellers);

  return (
    <div>
      <div
        className={`fixed inset-0 flex items-center justify-end bg-white bg-opacity-50 z-50 transition-opacity duration-500
        ${
          isSavedTravellerModalOpen
            ? "opacity-100"
            : "opacity-0 pointer-events-none"
        }`}
        onClick={() => setIsSavedTravellerModalOpen(false)}
      >
        <div
          ref={drawerRef}
          className={`bg-[#212121] p-8 lg:rounded-l-lg shadow-lg w-[600px] h-full transition-transform duration-300 transform ${
            isSavedTravellerModalOpen ? "translate-x-0" : "translate-x-full"
          }`}
          onClick={(e) => e.stopPropagation()}
        >
          <button
            type="button"
            onClick={handleClose}
            className="absolute top-4 right-4"
          >
            <MdClose size={24} color="white" />
          </button>
          <div className="flex justify-between items-center mb-8 p-4">
            <h2 className="md:text-3xl text-xl font-bold font-sans text-white">
              Saved Travellers
            </h2>
            <button
              className="bg-[#9b9b9b] hover:text-[#2F2F2F] px-2 py-1 rounded hover:bg-white text-white"
              onClick={() => {
                setIsSavedTravellerModalOpen(false);
                setIsAddTravelerModalOpen(true);
              }}
            >
              Add Traveller
            </button>
          </div>
          {travellers.length === 0 ? (
            <p className="text-center text-white">No travellers.</p>
          ) : (
            <ul className="p-4">
              {travellers.map((traveller) => (
                <li
                  key={traveller.id}
                  className="flex items-center mb-4 p-2 border border-white bg-[#2F2F2F] rounded text-white"
                >
                  <input
                    type="checkbox"
                    className="mr-2 cursor-pointer"
                    checked={selectedTravellers.includes(traveller.id)}
                    onChange={() => handleCheckboxChange(traveller.id)}
                  />
                  <span className="mr-2">
                    {traveller.first_name} {traveller.last_name}
                  </span>
                  <span className="mr-4">
                    ({traveller.gender === "MALE" ? "M" : "F"})
                  </span>
                  <FaTrashAlt
                    className="ml-auto cursor-pointer text-white"
                    onClick={() => handleDeleteTraveller(traveller.id)}
                  />
                </li>
              ))}
            </ul>
          )}
          {errorMessage && (
            <p className="text-red-500 text-center mt-4">{errorMessage}</p>
          )}
          <div className="fixed bottom-0 left-0 w-full md:w-[600px]w-full md:w-[600px] flex justify-center mt-6 p-4">
            <button
              onClick={() =>
                localStorage.getItem("Token")
                  ? handleConfirm()
                  : setShowLogin(true)
              }
              className={`absolute bottom-6 bg-[#9b9b9b] w-72 hover:bg-white text-[#2F2F2F] font-bold py-2 px-6 rounded focus:outline-none focus:ring-2 focus:ring-whitefocus:ring-opacity-50 ${
                selectedTravellers.length === 0
                  ? "opacity-50 cursor-not-allowed"
                  : ""
              }`}
              disabled={selectedTravellers.length === 0}
            >
              Confirm
            </button>
            {loading && <FaSpinner className="ml-4 animate-spin text-white" />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SavedTravellers;
