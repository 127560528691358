import { handleTokenExpiry, isTokenExpired } from "../utils/AuthUtil";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export const sessionClear = async () => {
  if (isTokenExpired()) {
    handleTokenExpiry();
    return;
  }
  try {
    const response = await fetch(`${SERVER_URL}/session_up/remove_session`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to Clear Session");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error("Error:", error);
  }
};

export const getSession = async () => {
  try {
    // Get the token from localStorage
    const token = localStorage.getItem("Token");

    // Define the headers object
    const headers = {
      "Content-Type": "application/json",
    };

    // Conditionally add the Authorization header
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    // Make the fetch request with the appropriate headers
    const response = await fetch(`${SERVER_URL}/session_up/get_session`, {
      method: "GET",
      headers: headers,
    });

    // Check if the response is not ok
    if (!response.ok) {
      throw new Error("Failed to Clear Session");
    }

    // Parse the response JSON
    const data = await response.json();

    // If a session_id is present in the response data, store it in localStorage
    if (data && data.session_id) {
      localStorage.setItem("FlightDesitionTree-ID", data.session_id);
    }

    // Return the response data
    return data;
  } catch (error) {
    // Throw an error if there was an issue in the try block
    throw new Error("Error:", error);
  }
};

