import React, { useContext, useEffect, useRef, useState } from "react";
import HotelFooter from "./HotelFooter";
import { HotelData } from "../../DummyData";
import HotelBookingFilterModal from "./HotelBookingFilterModal";
import Header from "../Header";
import { FaStar } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { FlightContext } from "../../utils/FlightContext";
import Login from "../auth/Login";
import SignUp from "../auth/SignUp";

const HotelChatScreen = ({ clearMessages, messages, setMessages }) => {
  const { showLogin, setShowLogin, showSignup, setShowSignup } =
    useContext(FlightContext);
  // const [messages, setMessages] = useState([]);
  const [selectedHotel, setSelectedHotel] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const navigate = useNavigate();

  const messagesEndRef = useRef(null);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  useEffect(() => {
    setShowLogin(false);
    setShowSignup(false);
  }, []);

  const addMessage = (sender, text) => {
    const updatedMessages = [...messages, { sender, text }];
    setMessages(updatedMessages);

    if (sender === "Human") {
      const botResponse = HotelData.find(
        (item) => item.human.toLowerCase() === text.toLowerCase()
      );

      setTimeout(() => {
        if (botResponse) {
          console.log("Bot response found:", botResponse);
          if (botResponse.type === "message") {
            setMessages((prevMessages) => [
              ...updatedMessages,
              { sender: "AI", text: botResponse.ai },
            ]);
          } else if (botResponse.type === "hotel_list") {
            setMessages((prevMessages) => [
              ...updatedMessages,
              { sender: "AI", type: "hotel_list", data: botResponse.ai.data },
            ]);
          } else if (botResponse.type === "location_message") {
            setMessages((prevMessages) => [
              ...updatedMessages,
              { sender: "AI", type: "location_message", data: botResponse.ai },
            ]);
          } else if (botResponse.type === "amenities_message") {
            setMessages((prevMessages) => [
              ...updatedMessages,
              { sender: "AI", type: "amenities_message", data: botResponse.ai },
            ]);
          } else if (botResponse.type === "rating_message") {
            setMessages((prevMessages) => [
              ...updatedMessages,
              { sender: "AI", type: "rating_message", data: botResponse.ai },
            ]);
          }
        } else {
          console.log("Bot response not found for text:", text);
          setMessages((prevMessages) => [
            ...updatedMessages,
            { sender: "AI", text: "Sorry, I don't understand that." },
          ]);
        }
      }, 0);
    }
  };

  const sendMessage = (value) => {
    if (value.trim()) {
      addMessage("Human", value.trim());
      setInputValue("");
    }
  };

  const handleHotelClick = (hotel) => {
    setSelectedHotel(hotel);
    setIsModalOpen(true); // Open modal when hotel is clicked
  };

  const handleCloseModal = () => {
    setSelectedHotel(null);
    setIsModalOpen(false); // Close modal when handled
  };

  const handleHotelDetails = (data) => {
    setMessages((prevMessages) => [
      ...prevMessages,
      { sender: "AI", type: "hotel_details", data },
    ]);
  };

  const handleHotelBooking = (event) => {
    event.preventDefault();
    // Find the hotel details in the dummy data
    const hotelBooking = HotelData.find(
      (item) => item.type === "hotel_booking"
    );
    // Pass the hotel details to the callback function
    if (hotelBooking) {
      console.log("hotels hotelBooking :", hotelBooking);
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: "AI", type: "hotel_booking", data: hotelBooking.ai.data },
      ]);
    }
  };

  console.log("messages : ", messages);

  return (
    <div className="flex flex-col h-screen bg-[#212121]">
      <Header clearMessages={clearMessages} />
      {messages.length > 0 ? (
        <div className="flex-1 overflow-y-auto bg-[#212121] p-4">
          {messages.map((msg, index) => (
            <div
              key={index}
              className={`my-2 p-2 px-4 rounded-full max-w-md w-fit ${
                msg.sender === "Human"
                  ? "ml-auto bg-[#2F2F2F] text-white text-right"
                  : "mr-auto text-white text-left"
              }`}
            >
              {msg.type === "hotel_list" && msg.data ? (
                <div className="bg-black rounded-lg shadow-md mb-4 border-[#424242] md:w-[600px] w-[300px]">
                  {msg.data.map((hotel, i) => (
                    <div
                      key={i}
                      className="border border-gray-500 p-2 rounded-lg mb-2 hover:bg-[#212121] cursor-pointer flex justify-between"
                      onClick={() => handleHotelClick(hotel)}
                    >
                      {console.log("hotel list data: ", msg.data)}
                      <div>
                        <h3 className="text-lg font-semibold">{hotel.name}</h3>
                        <div className="border rounded-lg w-fit px-1 mt-1">
                          Couple Friendly
                        </div>
                      </div>
                      <div>
                        <div className="flex items-center">
                          Rating: {hotel.rating} <FaStar className="ml-2" />
                        </div>
                        <div>₹ {hotel.price} Rs</div>
                        <div> + taxes {hotel.taxes}</div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : msg.type === "hotel_details" && msg.data ? (
                <div className="bg-black rounded-lg shadow-md mb-4 border-[#424242] md:w-[600px] w-[300px]">
                  {msg.data.map((hotel, i) => (
                    <div
                      key={i}
                      className="border border-gray-500 p-2 rounded-lg mb-2"
                    >
                      <div className="flex justify-between p-2">
                        <div className="flex flex-col w-full">
                          <div className="flex justify-center md:gap-64 gap-10">
                            <p className="text-FontSize1421">
                              Check-in
                              <div className="text-FontSize1824">
                                {hotel.offers[0].checkInDate}
                              </div>
                            </p>
                            <p className="text-FontSize1421">
                              Check-out
                              <div className="text-FontSize1824">
                                {hotel.offers[0].checkOutDate}
                              </div>
                            </p>
                          </div>
                          <div className="flex justify-center md:gap-20 mt-4">
                            <div>
                              <h3 className="text-lg font-semibold">
                                {hotel.hotel.name}
                              </h3>
                              <p className="text-FontSize1421 flex">
                                Number of Guests:
                                <div className="text-FontSize1824 ml-2">
                                  {hotel.offers[0].guests.adults}
                                </div>
                              </p>
                              <p className="text-FontSize1421 flex">
                                Room Type:
                                <div className="text-FontSize1824 ml-2">
                                  {hotel.offers[0].room.typeEstimated.category}
                                </div>
                              </p>
                              <p>
                                {hotel.offers[0].room.typeEstimated.beds}{" "}
                                {hotel.offers[0].room.typeEstimated.bedType} BED
                              </p>
                            </div>
                            <div className="ml-8 mt-6">
                              <p>₹ {hotel.offers[0].price.total} Rs</p>
                            </div>
                          </div>
                          <div className="flex justify-end mt-4">
                            <button
                              className="bg-blue-600 text-white p-2 rounded-lg"
                              onClick={handleHotelBooking}
                            >
                              Book
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : msg.type === "hotel_booking" && msg.data ? (
                <div className="bg-black rounded-lg shadow-md mb-4 border-[#424242] md:w-[500px] w-[300px]">
                  <div className="border border-gray-500 rounded-lg mb-2">
                    <div className="bg-blue-600 w-full h-10 rounded-t-lg items-center">
                      <h3 className="text-lg font-semibold text-center pt-1">
                        Booking Confirmation
                      </h3>
                    </div>
                    <div className="p-2">
                      <div className="text-FontSize2026">The OM Resort</div>

                      {msg.data.guests.map((guest, i) => (
                        <div key={i} className="mt-2">
                          <p className="text-FontSize1421">
                            Booking ID:{" "}
                            <text className="text-FontSize1621">
                              {guest.frequentTraveler[0].frequentTravelerId}{" "}
                            </text>
                          </p>

                          <div className="mt-2">
                            <p className="text-FontSize1421">
                              Check In:{" "}
                              <text className="text-FontSize1621">
                                {"2024-06-09"}{" "}
                              </text>
                            </p>
                            <p className="text-FontSize1421">
                              Check Out:{" "}
                              <text className="text-FontSize1621">
                                {"2024-06-10"}{" "}
                              </text>
                            </p>
                          </div>

                          <p className="text-FontSize1421 mt-3">
                            Guest Name:{" "}
                            <text className="text-FontSize1621">
                              {guest.title} {guest.firstName} {guest.lastName}
                            </text>
                          </p>
                          <p>Phone: {guest.phone}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ) : msg.type === "location_message" && msg.data ? (
                <div className="flex flex-col mt-2">
                  <span>{msg.data.text}</span>
                  <div className="flex flex-wrap mt-2">
                    {msg.data.options.map((option, i) => (
                      <button
                        key={i}
                        className="bg-[#2F2F2F] text-white p-2 rounded-3xl mr-3 mb-2 px-4"
                        onClick={() => addMessage("Human", option)} // Add location option as message when clicked
                      >
                        {option}
                      </button>
                    ))}
                  </div>
                </div>
              ) : msg.type === "amenities_message" && msg.data ? (
                <div className="flex flex-col mt-2">
                  <span>{msg.data.text}</span>
                  <div className="flex flex-wrap mt-2">
                    {msg.data.options.map((option, i) => (
                      <button
                        key={i}
                        className="bg-[#2F2F2F] text-white p-2 rounded-3xl mr-3 mb-2 px-4"
                        onClick={() => addMessage("Human", option)} // Add location option as message when clicked
                      >
                        {option}
                      </button>
                    ))}
                  </div>
                </div>
              ) : msg.type === "rating_message" && msg.data ? (
                <div className="flex flex-col mt-2">
                  <span>{msg.data.text}</span>
                  <div className="flex flex-wrap mt-2">
                    {msg.data.options.map((option, i) => (
                      <button
                        key={i}
                        className="bg-[#2F2F2F] text-white p-2 rounded-3xl mr-3 mb-2 px-4"
                        onClick={() => addMessage("Human", option)} // Add location option as message when clicked
                      >
                        {option}
                      </button>
                    ))}
                  </div>
                </div>
              ) : (
                msg.text
              )}
            </div>
          ))}
          <div ref={messagesEndRef} />
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center h-full">
          <div className="text-center px-4 md:text-2xl text-xl text-white">
            Hello! I'm an AI assistant designed to facilitate Hotel bookings.
            {/* <div className="text-sm">
                {" "}
                Please choose your preferred option.{" "}
              </div> */}
          </div>
          <div className="flex justify-around mt-4 gap-4">
            <div
              className="hover:bg-[#2F2F2F] border border-[#2F2F2F] text-white flex flex-col items-center justify-center px-4 py-2 rounded-full cursor-pointer"
              onClick={() => sendMessage("Kashmir")}
            >
              Kashmir
            </div>
            <div
              className="hover:bg-[#2F2F2F] border border-[#2F2F2F] text-white flex flex-col items-center justify-center px-4 py-2 rounded-full cursor-pointer"
              onClick={() => sendMessage("Puri")}
            >
              Puri
            </div>
            <div
              className="hover:bg-[#2F2F2F] border border-[#2F2F2F] text-white flex flex-col items-center justify-center px-4 py-2 rounded-full cursor-pointer "
              onClick={() => sendMessage("Bengaluru")}
            >
              Bengaluru
            </div>
          </div>
        </div>
      )}
      <HotelFooter addMessage={addMessage} />
      {selectedHotel && (
        <HotelBookingFilterModal
          hotel={selectedHotel}
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          onSubmit={handleHotelDetails}
        />
      )}
      {showLogin && <Login />}
      {showSignup && <SignUp />}
    </div>
  );
};

export default HotelChatScreen;
