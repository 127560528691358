import React, { createContext, useState } from "react";

export const FlightContext = createContext();

export const FlightProvider = ({ children }) => {
  const [flightOffer, setFlightOffer] = useState(null);
  const [flightDetails, setFlightDetails] = useState(null);
  const [flightOrderData, setFlightOrderData] = useState(null);
  const [domesticFlight, setDomesticFlight] = useState(false);
  const [docRequired, setDocRequired] = useState(false);
  const [isAddTravelerModalOpen, setIsAddTravelerModalOpen] = useState(false);
  const [isSavedTravellerModalOpen, setIsSavedTravellerModalOpen] =
    useState(false);
  const [travellers, setTravellers] = useState([]);
  const [showLogin, setShowLogin] = useState(false);
  const [showSignup, setShowSignup] = useState(false);
  const [showSkeleton, setShowSkeleton] = useState(false);

  return (
    <FlightContext.Provider
      value={{
        flightOffer,
        setFlightOffer,
        flightOrderData,
        setFlightOrderData,
        domesticFlight,
        setDomesticFlight,
        docRequired,
        setDocRequired,
        flightDetails,
        setFlightDetails,
        isAddTravelerModalOpen,
        setIsAddTravelerModalOpen,
        isSavedTravellerModalOpen,
        setIsSavedTravellerModalOpen,
        travellers, setTravellers,
        showLogin, setShowLogin,
        showSignup, setShowSignup,
        showSkeleton, setShowSkeleton
      }}
    >
      {children}
    </FlightContext.Provider>
  );
};
