import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { login } from "../../services/auth";
import { MdClose } from "react-icons/md";
import { getSession } from "../../services/SessionUp";
import { FlightContext } from "../../utils/FlightContext";
import { FaSpinner } from "react-icons/fa";

const Login = () => {
  const {
    showLogin, setShowLogin ,
    showSignup, setShowSignup
  } = useContext(FlightContext);
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pswrdError, setPswrdError] = useState(false);
  const [mobileError, setMobileError] = useState(false);
  const navigate = useNavigate();
  const drawerRef = useRef();

  useEffect(() => {
    setIsOpen(true);
    setShowSignup(false)
  }, []);

  const handleMobileChange = (e) => {
    setMobile(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const loginData = {
      contact_no: mobile,
      password: password,
    };
    
      const response = await login(loginData);
      console.log("res: ",response);
      if (response) {
        if (response.non_field_errors) {
          if (response.non_field_errors[0] === "Invalid password") {
            setPswrdError(true);
            setTimeout(() => {
              setPswrdError(false);
            }, 3000);
          } else {
            setMobileError(true);
            setTimeout(() => {
              setMobileError(false);
            }, 3000);
          }
        }
        console.log("res login  ", response);
        if (!localStorage.getItem("FlightDesitionTree-ID"))
          {
            getSession();
          }
          if (response.refresh || response.access) {
            setShowLogin(false);
          }
        
      } 
    
    setLoading(false);
    console.log("Mobile:", mobile);
    console.log("Password:", password);
  };

  const handleClose = () => {
    setIsOpen(false);
    setTimeout(() => setShowLogin(false), 300); // Delay navigation to allow animation to complete
  };

  const handleClickOutside = (e) => {
    if (drawerRef.current && !drawerRef.current.contains(e.target)) {
      handleClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div
      className={`fixed inset-0 flex items-center justify-end bg-white bg-opacity-50 z-50 transition-opacity duration-500 ${
        isOpen ? "opacity-100" : "opacity-0"
      }`}
    >
      <div
        ref={drawerRef}
        className={`bg-[#212121] p-8 lg:rounded-l-lg shadow-lg w-[600px] h-full transition-transform duration-300 transform ${
          isOpen ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <button
          type="button"
          onClick={handleClose}
          className="absolute top-4 right-4"
        >
          <MdClose size={24} color="white" />
        </button>
        <form
          onSubmit={handleSubmit}
          className="flex flex-col items-center mt-20"
        >
        <div className="font-bold text-white mb-6 text-2xl text-center">
          Login to your account
        </div>
          <div className="mb-4">
            <label className="block text-white mb-2">Mobile Number:</label>
            <input
              type="text"
              value={mobile}
              onChange={handleMobileChange}
              maxLength={10}
              pattern="[0-9]*"
              required
              className="w-80 px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-white"
            />
            {mobileError && (
              <div className="text-red-500 text-sm mt-2">
                Invalid mobile number
              </div>
            )}
          </div>
          <div className="mb-10">
            <label className="block text-white mb-2">Password:</label>
            <input
              type="password"
              value={password}
              onChange={handlePasswordChange}
              required
              className="w-80 px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-white"
            />
            {pswrdError && (
              <div className="text-red-500 text-sm mt-2">
                Incorrect password
              </div>
            )}
          </div>
          <div className="flex items-center">
            <button
              type="submit"
              className="w-80 bg-[#2F2F2F] text-white py-2 rounded-lg hover:bg-[#676767] transition duration-300"
              disabled={loading} // Disable button while loading
            >
              Login
            </button>
            {loading && (
              <FaSpinner className="ml-4 animate-spin text-white" />
            )}
          </div>
          <div className="flex items-center justify-center mt-3">
            <div className="text-sm font-bold text-white mr-2">
              Don't have an account ?
            </div>
            <div
              className="text-blue-500 hover:cursor-pointer"
              onClick={() => {
                setShowSignup(true)
                setShowLogin(false)
                }}
            >
              Sign Up
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
