import React, { useContext, useEffect } from "react";
import { getSession } from "../services/SessionUp";
import { useNavigate } from "react-router-dom";
import { MdOpenInNew } from "react-icons/md";
import { ReactComponent as SulopaLogo } from "../assets/Sulopa_white_logo.svg";
import { FlightContext } from "../utils/FlightContext";

const Sidebar = ({ clearMessages }) => {
  const {
    showSkeleton, setShowSkeleton
  } = useContext(FlightContext);
  const navigate = useNavigate();

  const handleNewChatClick = () => {
    setShowSkeleton(false);
    clearMessages();
    getSession();
  };
  const handleMain = () => {
    setShowSkeleton(false);
    clearMessages();
    getSession();
    navigate("/");
  };

  useEffect(() => {
    if(!localStorage.getItem("Token")){
      getSession();
    }
  },[])

  console.log("token::  ", localStorage.getItem("Token"));

  return (
    <div className="hidden md:block">
      <div className="bg-[#0d0d0d] min-h-screen flex justify-between flex-col px-4 border-b-[1px] border-gray-200 w-[300px] pt-4">
        <div className="flex flex-col items-center py-2">
          <div className="px-4" onClick={handleMain}>
            {/* <SulopaLogo className="w-52 h-14 cursor-pointer" /> */}
            <div className="text-FontSize2026 text-white text-center font-bold cursor-pointer">AITravelAssist</div>
            <div className="text-FontSize1020 text-white text-center ml-[80px] mt-[-7px] cursor-pointer">
              by Sulopa
            </div>
          </div>
          <div
            className="flex items-center justify-center text-[#fff] bg-[#212121] font-Primary py-2 px-2 rounded-lg cursor-pointer h-12 w-full text-center hover:bg-[#2F2F2F] mt-2"
            onClick={handleNewChatClick}
          >
            <span className="mr-2">New Chat</span>
            <MdOpenInNew size={20} color="#fff" className="ml-14" />
          </div>
        </div>

        <div className="flex justify-center items-center py-2 pb-8 px-4">
          <span className="text-white">
            Powered by{" "}
            <a
              href="https://sulopa.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:underline"
            >
              Sulopa
            </a>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
