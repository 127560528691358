import { handleTokenExpiry, isTokenExpired } from "../utils/AuthUtil";
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const sendChatMessage = async (message) => {
  console.log("message sentttt");
  try {
    // Get the token from localStorage
    const token = localStorage.getItem("Token");

    // Define the headers object
    const headers = {
      "Content-Type": "application/json",
      "FlightDesitionTree-ID": localStorage.getItem("FlightDesitionTree-ID"),
    };

    // Conditionally add the Authorization header
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    // Make the fetch request with the appropriate headers
    const response = await fetch(`${SERVER_URL}/chat/post`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify({ message }),
    });
    console.log("response header: ", response);
    // Check if the response is not ok
    if (!response.ok) {
      throw new Error("Failed to send message");
    }

    // Parse the response JSON
    const data = await response.json();
    return data;
  } catch (error) {
    // Throw an error if there was an issue in the try block
    throw new Error("Error sending message:", error);
  }
};


const sendAudioMessage = async (message) => {
  // if (isTokenExpired()) {
  //   handleTokenExpiry();
  //   return;
  // }
  try {
    const token = localStorage.getItem("Token");

    const headers = {
      "FlightDesitionTree-ID": localStorage.getItem("FlightDesitionTree-ID"),
    };

    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }
    const response = await fetch(`${SERVER_URL}/voice/post`, {
      method: "POST",
      headers: headers,
      body: message,
    });

    console.log("esponse haede:L ", response);

    if (!response.ok) {
      throw new Error("Failed to send message");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error("Error sending message:", error);
  }
};

const getChatHistory = async (chat_id) => {
  // if (isTokenExpired()) {
  //   handleTokenExpiry();
  //   return;
  // }
  try {
    const token = localStorage.getItem("Token");

    const headers = {
      "Content-Type": "application/json",
      "FlightDesitionTree-ID": localStorage.getItem("FlightDesitionTree-ID"),
    };

    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }
    const response = await fetch(`${SERVER_URL}/chat_history/`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify({ chat_id }),
    });

    console.log("esponse haede:L ", response);

    if (!response.ok) {
      throw new Error("Failed to send message");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error("Error sending message:", error);
  }
};

export default {
  sendChatMessage,
  sendAudioMessage,
  getChatHistory,
};
