export const isTokenExpired = () => {
  const expiry = localStorage.getItem('TokenExpiry');
  // Check if expiry is null or undefined
  console.log("kkkkk expiry ?",localStorage.getItem('TokenExpiry'));
  if (!expiry) {
      console.log("kkkkk expiry ooooo?");
      return true; // Treat token as expired if expiry is not set
  }
  return Date.now() > expiry;  
};
  
  export const handleTokenExpiry = () => {
    localStorage.removeItem('Token');
    localStorage.removeItem('TokenExpiry');
  };
  