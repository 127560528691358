import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Sidebar from "./components/Sidebar";
import ChatScreen from "./components/ChatScreen";
import SignUp from "./components/auth/SignUp";
import Login from "./components/auth/Login";
import Profile from "./components/Profile";
import AddTraveller from "./components/Traveller/AddTraveller";
import { FlightProvider } from "./utils/FlightContext";
import { handleTokenExpiry, isTokenExpired } from "./utils/AuthUtil";
import HotelChatScreen from "./components/Hotel/HotelChatScreen";
import LandingScreen from "./components/LandingScreen";

function App() {
  const [messages, setMessages] = useState([]);
  const [AIMessages, setAIMessages] = useState([]);
  const [msgType, setMsgType] = useState("");
  const [loading, setLoading] = useState(false);
  const [flightOffer, setFlightOffer] = useState([]);
  const [hotelMessages, setHotelMessages] = useState([]);

  const addMsgType = (type) => {
    setMsgType(type);
  };

  const addFlightOffer = (message) => {
    setFlightOffer([...flightOffer, message]);
  };

  const addAIMessage = (message) => {
    console.log("adding aimsg", message);
    setAIMessages((prevAIMessages) => {
      console.log("adding aimsgAIMessages>>", prevAIMessages);
      return [...prevAIMessages, message];
    });
  };

  console.log("adding aimsgaimessages", AIMessages, "messages", messages);

  const addMessage = (message) => {
    setMessages((prevMessages) => {
      return [...prevMessages, message];
    });
  };

  const clearMessages = () => {
    setMessages([]);
    setAIMessages([]);
    setFlightOffer([]);
    setHotelMessages([]);
  };

  useEffect(() => {
    const checkTokenExpiryInterval = setInterval(() => {
      if (isTokenExpired()) {
        handleTokenExpiry();
      }
    }, 60000); // Check every 60 seconds

    return () => clearInterval(checkTokenExpiryInterval);
  }, []);

  console.log(
    "destination id: ",
    localStorage.getItem("FlightDesitionTree-ID")
  );

  return (
    <FlightProvider>
      <Router>
        <div className="flex h-full w-full">
          <Sidebar clearMessages={clearMessages} className="" />
          <div className="flex-1">
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/profile" element={<Profile />} />
              <Route
                path="/addtravelers"
                element={
                  <AddTraveller
                    addAIMessage={addAIMessage}
                    addMsgType={addMsgType}
                    setLoading={setLoading}
                  />
                }
              />
              <Route
                path="/"
                element={
                  <LandingScreen
                    messages={messages}
                    addMessage={addMessage}
                    AIMessages={AIMessages}
                    addAIMessage={addAIMessage}
                    msgType={msgType}
                    addMsgType={addMsgType}
                    loading={loading}
                    setLoading={setLoading}
                    flightOffer={flightOffer}
                    setFlightOffer={setFlightOffer}
                    addFlightOffer={addFlightOffer}
                    clearMessages={clearMessages}
                  />
                }
              />
              <Route
                path="/flight"
                element={
                  <ChatScreen
                    messages={messages}
                    addMessage={addMessage}
                    AIMessages={AIMessages}
                    addAIMessage={addAIMessage}
                    msgType={msgType}
                    addMsgType={addMsgType}
                    loading={loading}
                    setLoading={setLoading}
                    flightOffer={flightOffer}
                    setFlightOffer={setFlightOffer}
                    addFlightOffer={addFlightOffer}
                    clearMessages={clearMessages}
                  />
                }
              />
              <Route
                path="/hotel"
                element={
                  <HotelChatScreen
                    clearMessages={clearMessages}
                    messages={hotelMessages}
                    setMessages={setHotelMessages}
                  />
                }
              />
            </Routes>
          </div>
        </div>
      </Router>
    </FlightProvider>
  );
}

export default App;
