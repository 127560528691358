import React from "react";

const DotWaveLoader = () => {
  return (
    <div className="self-start w-[300px] p-4">
      <div className="flex space-x-2">
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
      </div>
      {/* <div className="self-start w-[300px]">
            <div class="shadow rounded-md p-4 max-w-sm w-full">
              <div class="animate-pulse flex space-x-4">
                <div class="flex-1 space-y-6 py-1">
                  <div class="space-y-3">
                    <div class="grid grid-cols-3 gap-4">
                      <div class="h-2 bg-[#676767] rounded col-span-2"></div>
                      <div class="h-2 bg-[#676767] rounded col-span-1"></div>
                    </div>
                    <div class="h-2 bg-[#676767] rounded"></div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
    </div>
  );
};

export default DotWaveLoader;
