import React, { useContext, useEffect, useRef, useState } from "react";
import {
  MdFlightTakeoff,
  MdFlightLand,
  MdLogout,
  MdLogin,
} from "react-icons/md";
import { logout } from "../services/auth";
import { useNavigate } from "react-router-dom";
import Login from "./auth/Login";
import MenuBar from "./MenuBar";
import { FlightContext } from "../utils/FlightContext";
import DummyProfilePic from '../assets/user.png';

const Header = ({clearMessages}) => {
  const {
    showLogin, setShowLogin ,
    showSignup, setShowSignup
  } = useContext(FlightContext);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  const toggleDropdown = () => {
    console.log("onclicktoggle: ",dropdownVisible);
    setDropdownVisible(!dropdownVisible);
  };

  const handleLogin = () => {
    setShowLogin(true);
    setDropdownVisible(false); // Close dropdown after login
  };

  const handleLogout = async () => {
    try {
      const response = await logout();
      console.log("logout res: ", response);
      if (response.message === "Logout successful.") {
        localStorage.removeItem("Token");
        localStorage.removeItem('TokenExpiry');
      }
    } catch (error) {
      console.error("Logout Error! ", error);
    }
    setDropdownVisible(false); // Close dropdown after logout
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    if (dropdownVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownVisible]);

  return (
    <div className="flex justify-end pr-8 pt-4 pb-2">
    <MenuBar clearMessages={clearMessages}/>
      <div
        className="h-10 w-10 rounded-full bg-white cursor-pointer text-FontSize24 flex items-center justify-center"
        onClick={toggleDropdown}
      >
        {/* <DummyProfilePic /> */}
        <img src={DummyProfilePic} className="rounded-full" color="white"/>
      </div>
      {dropdownVisible && (
        <div
          ref={dropdownRef}
          className="absolute top-0 mt-[60px] mr-6 right-0 w-40 bg-[#2F2F2F] border border-[#676767] rounded-lg shadow-lg z-50 p-2"
        >
          {!localStorage.getItem("Token") ? (
            <div
              className="px-4 py-1 text-white hover:bg-[#424242] cursor-pointer flex items-center rounded-lg"
              onClick={handleLogin}
            >
              <MdLogin className="mr-2" />
              Login
            </div>
          ) : (
            <>
              {/* <div
                  className="px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer"
                  onClick={() => navigate("/profile")}
                >
                  Profile
                </div> */}
              <div
                className="px-4 py-1 text-white hover:bg-[#424242] cursor-pointer flex items-center rounded-lg"
                onClick={handleLogout}
              >
                <MdLogout className="mr-2" />
                Logout
              </div>
            </>
          )}
        </div>
      )}
      {openLogin && <Login />}
    </div>
  );
};

export default Header;
