import React, { useEffect, useState, useRef } from "react";
import {
  FaRegPaperPlane as SendIcon,
  FaMicrophone as MicIcon,
} from "react-icons/fa";
import { RiVoiceRecognitionFill as RecognitionIcon } from "react-icons/ri";
import { Tooltip } from "react-tooltip";

const HotelFooter = ({ addMessage }) => {
  const [inputValue, setInputValue] = useState("");
  const [uploadAudio, setUploadAudio] = useState(false);
  const [recording, setRecording] = useState(false);
  const [audioChunks, setAudioChunks] = useState([]);
  const audioRecorderRef = useRef(null);
  const recordingTimeoutRef = useRef(null);

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        audioRecorderRef.current = new MediaRecorder(stream);
        audioRecorderRef.current.addEventListener("dataavailable", (e) => {
          setAudioChunks((prevAudioChunks) => [...prevAudioChunks, e.data]);
        });
      })
      .catch((err) => {
        console.error("Error: " + err);
      });
  }, []);

  const startRecording = () => {
    console.log("Starting recording");
    setAudioChunks([]);
    setRecording(true);
    audioRecorderRef.current.start();
  };

  const stopRecording = () => {
    console.log("Stop recording");
    audioRecorderRef.current.stop();
    setRecording(false);
    setUploadAudio(true);
  };

  const handleStart = () => {
    startRecording();
    recordingTimeoutRef.current = setTimeout(() => {
      stopRecording();
    }, 5000); // Automatically stop recording after 5 seconds
  };

  const handleEnd = () => {
    clearTimeout(recordingTimeoutRef.current);
    stopRecording();
  };

  const sendMessage = () => {
    if (inputValue.trim()) {
      addMessage("Human", inputValue.trim());
      setInputValue("");
    }
  };

  return (
    <div className="md:px-32" style={{ width: "100%" }}>
      <div className="flex items-center justify-between bg-[#212121] text-white pb-4 lg:pb-8">
        <input
          type="text"
          placeholder="Type your message for hotel booking"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              sendMessage();
            }
          }}
          className="flex-1 mr-2 p-2 pl-6 rounded-full focus:outline-none focus:border-[#9b9b9b] bg-[#2F2F2F] h-12"
        />
        <button
          className="flex items-center justify-center w-12 h-12 rounded-full lg:bg-[#9b9b9b] bg-white text-white hover:bg-white focus:outline-none"
          onClick={sendMessage}
        >
          <SendIcon size={22} color="#2F2F2F" />
        </button>
        <button
          className={`flex items-center justify-center w-12 h-12 rounded-full text-white ${
            recording ? "hover:bg-red-500" : "hover:bg-[#9b9b9b]"
          } focus:outline-none ml-2 ${
            recording ? "bg-red-500" : ""
          } hide-on-mobile`}
          onMouseDown={startRecording}
          onMouseUp={stopRecording}
          onTouchStart={handleStart}
          onTouchEnd={handleEnd}
          onTouchCancel={handleEnd}
        >
          {recording ? (
            <RecognitionIcon size={22} />
          ) : (
            <MicIcon
              size={22}
              data-tooltip-id="my-tooltip"
              data-tooltip-content="Press and Hold to Record your Voice!"
              data-tooltip-place="top"
              color="#fff"
            />
          )}
        </button>
        <Tooltip id="my-tooltip" />
      </div>
    </div>
  );
};

export default HotelFooter;
