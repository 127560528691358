import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { signUp } from "../../services/auth";
import { MdClose } from "react-icons/md";
import { getSession } from "../../services/SessionUp";
import { FlightContext } from "../../utils/FlightContext";
import { FaSpinner } from "react-icons/fa";

const SignUp = () => {
  const {
    showLogin, setShowLogin ,
    showSignup, setShowSignup
  } = useContext(FlightContext);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [isOpen, setIsOpen] = useState(true); // Drawer open by default
  const navigate = useNavigate();
  const drawerRef = useRef();

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleMobileChange = (e) => {
    setMobile(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setPasswordsMatch(e.target.value === confirmPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setPasswordsMatch(e.target.value === password);
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    if (!passwordsMatch) {
      // Passwords don't match, set error state
      setPasswordsMatch(false);
      return;
    } else {
      const signUpData = {
        name: name,
        email: email,
        contact_no: mobile,
        password: password,
      };
      try {
        const response = await signUp(signUpData);
        if (response) {
          console.log("res sign up : ", response);
          if (!localStorage.getItem("FlightDesitionTree-ID"))
            {
              getSession();
            }
          setShowSignup(false);
        }
      } catch (error) {
        console.error("Error Sign Up: ", error);
      }
      setLoading(false);
    }
    // Here you can handle form submission, for now, just log the values
    console.log("----------------------------------------------");
    console.log("Name:", name);
    console.log("Email:", email);
    console.log("Mobile:", mobile);
    console.log("Password:", password);
    console.log("Confirm Password:", confirmPassword);
    console.log("----------------------------------------------");
  };

  useEffect(() => {
    setIsOpen(true);
  }, []);

  const handleClose = () => {
    setIsOpen(false);
    setTimeout(() => setShowSignup(false), 300); // Delay navigation to allow animation to complete
  };

  const handleClickOutside = (e) => {
    if (drawerRef.current && !drawerRef.current.contains(e.target)) {
      handleClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div
      className={`fixed inset-0 flex items-center justify-end bg-white bg-opacity-50 z-50 transition-opacity duration-300 ${
        isOpen ? "opacity-100" : "opacity-0"
      }`}
    >
      <div
        ref={drawerRef}
        className={`bg-[#212121] p-8 lg:rounded-l-lg shadow-lg w-[600px] h-full transition-transform duration-300 transform ${
          isOpen ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <button type="button" onClick={handleClose} className="absolute top-4 right-4">
          <MdClose size={24} color="white"/>
        </button>
        <form
        onSubmit={handleSubmit}
        className="flex flex-col items-center mt-20"
      >
        <div className="font-bold text-white mb-6 text-3xl text-center">
          Sign Up
        </div>
        <div className="mb-4">
          <label className="block text-white mb-2">Name:</label>
          <input
            type="text"
            value={name}
            onChange={handleNameChange}
            className="w-80 px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-white"
          />
        </div>
        <div className="mb-4">
          <label className="block text-white mb-2">Email:</label>
          <input
            type="email"
            value={email}
            onChange={handleEmailChange}
            className="w-80 px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-white"
          />
        </div>
        <div className="mb-4">
          <label className="block text-white mb-2">Mobile Number:</label>
          <input
            type="text"
            value={mobile}
            onChange={handleMobileChange}
            maxLength={10}
            pattern="[0-9]*"
            required
            className="w-80 px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-white"
          />
        </div>
        <div className="mb-4">
          <label className="block text-white mb-2">Password:</label>
          <input
            type="password"
            value={password}
            onChange={handlePasswordChange}
            required
            className="w-80 px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-white"
          />
        </div>
        <div className="mb-6">
          <label className="block text-white mb-2">Confirm Password:</label>
          <input
            type="password"
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            required
            className="w-80 px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-white"
          />
          {!passwordsMatch && <div className="text-red-500 text-sm">Passwords do not match</div>}
        </div>
        <div className="flex items-center">
        <button
          type="submit"
          className="w-80 bg-[#2F2F2F] text-white py-2 rounded-lg hover:bg-[#676767] transition duration-300"
          disabled={loading}
        >
          Submit
        </button>
        {loading && (
              <FaSpinner className="ml-4 animate-spin text-white" />
            )}
        </div>
        
        <div class="flex items-center justify-center mt-3">
          <div class="text-sm font-bold text-white mr-2">
            Already have an account?
          </div>
          <div
            class="text-blue-500 hover:cursor-pointer"
            onClick={() => {
              // setShowSignup(false)
              setShowLogin(true)
              }}
          >
            Login
          </div>
        </div>
      </form>
      </div>
    </div>
  );
};

export default SignUp;
