import React, { useContext, useEffect } from "react";
import { FaBus } from "react-icons/fa";
import { FaTrainSubway } from "react-icons/fa6";
import { GiCommercialAirplane } from "react-icons/gi";
import { RiHotelFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import { FlightContext } from "../utils/FlightContext";
import Login from "./auth/Login";
import SignUp from "./auth/SignUp";
import Footer from "./Footer";
import LandingScreenFooter from "./LandingScreenFooter";
import Chat from "../services/Chat";

const LandingScreen = ({
  addMessage,
  addAIMessage,
  addMsgType,
  loading,
  setLoading,
  addFlightOffer,
  clearMessages
}) => {
  const { showLogin, setShowLogin, showSignup, setShowSignup } =
    useContext(FlightContext);
  const navigate = useNavigate();

  const handleTransportClick = async (transportMode) => {

    try {
      const response = await Chat.sendChatMessage(transportMode);
      clearMessages();
    } catch (error) {
      console.error("Error sending message::::", error);
    }

  };

  useEffect(() => {
    setShowLogin(false);
    setShowSignup(false);
  }, []);

  return (
    <div className="flex flex-col h-screen bg-[#212121]">
      <Header clearMessages={clearMessages} />
      <div className="flex flex-col items-center justify-center h-full overflow-y-auto pb-20">
        <div className="text-center px-4 md:text-2xl text-xl text-white">
          Hello! I'm an AI assistant designed to facilitate bookings.
          <div className="text-sm"> Please choose your preferred option. </div>
        </div>
        <div className="flex justify-around mt-4 gap-4">
          {/* <div
            className="hover:bg-[#2F2F2F] border border-[#2F2F2F] text-white flex flex-col items-center justify-center px-4 py-2 rounded-md cursor-pointer md:h-[80px] md:w-[100px]"
              onClick={() => handleTransportClick("Bus")}
          >
            <FaBus className="mb-1 block md:hidden" size={20} color="white" />
            <FaBus className="mb-1 hidden md:block" size={30} color="white" />
            Bus
          </div> */}
          <div
            className="hover:bg-[#2F2F2F] border border-[#2F2F2F] text-white flex flex-col items-center justify-center px-4 py-2 rounded-md cursor-pointer md:h-[80px] md:w-[100px]"
            onClick={() => {
              navigate("/flight");
              // handleTransportClick('flight');
              }}
          >
            <GiCommercialAirplane
              className="mb-1 block md:hidden"
              size={20}
              color="white"
            />
            <GiCommercialAirplane
              className="mb-1 hidden md:block"
              size={30}
              color="white"
            />
            Flight
          </div>
          {/* <div
            className="hover:bg-[#2F2F2F] border border-[#2F2F2F] text-white flex flex-col items-center justify-center px-4 py-2 rounded-md cursor-pointer md:h-[80px] md:w-[100px]"
              onClick={() => handleTransportClick("Train")}
          >
            <FaTrainSubway
              className="mb-1 hidden md:block"
              size={30}
              color="white"
            />
            <FaTrainSubway
              className="mb-1 block md:hidden"
              size={20}
              color="white"
            />
            Train
          </div> */}
          <div
            className="hover:bg-[#2F2F2F] border border-[#2F2F2F] text-white flex flex-col items-center justify-center px-4 py-2 rounded-md cursor-pointer md:h-[80px] md:w-[100px]"
            onClick={() => navigate("/hotel")}
          >
            <RiHotelFill
              className="mb-1 hidden md:block"
              size={30}
              color="white"
            />
            <RiHotelFill
              className="mb-1 md:hidden block"
              size={20}
              color="white"
            />
            Hotel
          </div>
        </div>
      </div>
      <LandingScreenFooter
        addMessage={addMessage}
        addAIMessage={addAIMessage}
        addMsgType={addMsgType}
        setLoading={setLoading}
        addFlightOffer={addFlightOffer}
      />
      {showLogin && <Login />}
      {showSignup && <SignUp />}
    </div>
  );
};

export default LandingScreen;
