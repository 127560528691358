import React, { useContext, useEffect, useRef, useState } from "react";
import { FaPlane } from "react-icons/fa";
import Chat from "../services/Chat";
import { flightConfirmPrice } from "../services/flight";
import { FlightContext } from "../utils/FlightContext";
import AddTraveller from "./Traveller/AddTraveller";
import SavedTravellers from "./Traveller/SavedTravellers";
import Footer from "./Footer";
import { MdFlightTakeoff, MdFlightLand } from "react-icons/md";
import Header from "./Header";
import Login from "./auth/Login";
import SignUp from "./auth/SignUp";
import DotWaveLoader from "./Common/DotWaveLoader";

const ChatScreen = ({
  messages,
  addMessage,
  AIMessages,
  addAIMessage,
  addMsgType,
  loading,
  setLoading,
  flightOffer,
  addFlightOffer,
  clearMessages,
}) => {
  const [histSkeleton, setHistSkeleton] = useState(false);
  const [flightDetails, setFlightDetailsLocal] = useState(null);
  const {
    setFlightOffer: setGlobalFlightOffer,
    flightOrderData,
    setFlightOrderData,
    setDocRequired,
    flightDetails: globalFlightDetails,
    setFlightDetails: setGlobalFlightDetails,
    isAddTravelerModalOpen,
    setIsSavedTravellerModalOpen,
    showLogin,
    setShowLogin,
    showSignup,
    setShowSignup,
    showSkeleton,
    setShowSkeleton,
  } = useContext(FlightContext);
  const [date1, setDate1] = useState("");
  const [date2, setDate2] = useState("");
  const [date3, setDate3] = useState("");

  useEffect(() => {
    const today = new Date();
    const date1 = new Date(today);
    date1.setDate(today.getDate() + 14); // 14 days from today
    const date2 = new Date(today);
    date2.setDate(today.getDate() + 30); // 30 days from today
    const date3 = new Date(today);
    date3.setDate(today.getDate() + 7); // 7 days from today

    const formatDate = (date) => {
      const options = { month: "long", day: "numeric" };
      return date.toLocaleDateString("en-US", options);
    };

    setDate1(formatDate(date1));
    setDate2(formatDate(date2));
    setDate3(formatDate(date3));
  }, []);

  const messagesEndRef = useRef(null);

  useEffect(() => {
    setShowLogin(false);
    setShowSignup(false);
  }, []);

  useEffect(() => {
    const fetchChatHistory = async () => {
      setHistSkeleton(true);
      try {
        clearMessages();
        const id = localStorage.getItem("FlightDesitionTree-ID");
        const response = await Chat.getChatHistory(id);
        if (response) {
          setHistSkeleton(false);
        }
        response.history.map((chat) => {
          if (chat.user_response.type === "message") {
            // addMessage(chat.user_response.message);
            addAIMessage({
              message: chat.user_response.message,
              category: "user_message",
            });
          }
          const aiResponse = chat.ai_response;
          if (aiResponse.type === "flight_offers_search") {
            if (aiResponse.message.assistant) {
              addAIMessage({
                message: aiResponse.message,
                category: "audio_AI_FlightOffer",
              });
            } else {
              addAIMessage({
                message: aiResponse.message,
                category: "AI_FlightOffer",
              });
              addMsgType(aiResponse.type);
            }
          } else if (aiResponse.message.type === "flight-offers-pricing") {
            addAIMessage({
              message: aiResponse.message.flightOffers,
              category: "AI_FlightOfferPrice",
            });
            console.log(
              "inside light oper pricing :L ",
              aiResponse.message.flightOffers
            );
            addMsgType(aiResponse.type);
            setGlobalFlightDetails(aiResponse.message);
          } else if (aiResponse.type === "flight_order") {
            addAIMessage({
              message: aiResponse.message,
              category: "AI_FlightOrder",
            });
            addMsgType(aiResponse.type);
            setFlightOrderData(aiResponse.message);
          } else {
            if (aiResponse.message.assistant) {
              addAIMessage({
                message: aiResponse.message,
                category: "audio_message",
              });
            } else {
              addAIMessage({
                message: aiResponse.message,
                category: aiResponse.type,
              });
            }
            addMsgType(aiResponse.type);
          }
        });
      } catch (error) {
        setHistSkeleton(false);
        console.error("Failed to fetch chat history:", error);
      }
      setHistSkeleton(false);
    };

    fetchChatHistory();
  }, []);

  useEffect(() => {
    if (globalFlightDetails) {
      setFlightDetailsLocal(globalFlightDetails);
    }
  }, [globalFlightDetails]);

  console.log("aimessaggess: ", AIMessages);

  const handleTransportClick = async (transportMode) => {
    setShowSkeleton(true);
    // setLoading(true);
    // addMessage(transportMode);
    addAIMessage({
      message: transportMode,
      category: "user_message",
    });
    try {
      const response = await Chat.sendChatMessage(transportMode);
      if (response) {
        setShowSkeleton(false);
      }
      if (response.message.type === "flight_offers_search") {
        if (response.message.message.offer.length === 0) {
          setShowSkeleton(true);
          return null;
        }
        console.log("flight offer: ", response.message.message);
        addFlightOffer(response.message.message, "AI_FlightOffer");
        addAIMessage({
          message: response.message.message,
          category: "AI_FlightOffer",
        });
        addMsgType(response.message.type);
      } else {
        addAIMessage({
          message: response.message.message,
          category: "message",
        });
        addMsgType(response.message.type);
      }
    } catch (error) {
      console.error("Error sending message::::", error);
    }
    setShowSkeleton(false);
  };

  const handleFlightConfirmPrice = async (totalData) => {
    setShowSkeleton(true);
    try {
      const response = await flightConfirmPrice(totalData);
      if (response) {
        setShowSkeleton(false);
      }
      addAIMessage({
        message: response.message.message,
        category: "AI_FlightOfferPrice",
      });
      setDocRequired(
        response.message.message.bookingRequirements.travelerRequirements
          ? response.message.message.bookingRequirements.travelerRequirements[0]
              ?.documentRequired
          : false
      );
      localStorage.setItem(
        "isDomestic",
        response.message.message.bookingRequirements.travelerRequirements
          ? false
          : true
      );
      localStorage.setItem(
        "docReq",
        response.message.message.bookingRequirements.travelerRequirements
          ? response.message.message.bookingRequirements.travelerRequirements[0]
              ?.documentRequired
          : false
      );
      addMsgType(response.message.message.type);
      setFlightDetailsLocal(response.message.message);
      setGlobalFlightDetails(response.message.message);
    } catch (error) {
      setShowSkeleton(false);
      console.error("Error : ", error);
    }
    setShowSkeleton(false);
  };

  const formatTime = (datetime) => {
    const date = new Date(datetime);
    return date.toTimeString().split(" ")[0].slice(0, 5); // Get HH:MM part
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    return ` ${day} ${month} ${year}`;
  };

  const formatDuration = (duration) => {
    // Regular expression to extract hours and minutes
    const durationRegex = /PT(?:(\d+)H)?(?:(\d+)M)?/;
    const matches = duration.match(durationRegex);

    const hours = matches[1] ? `${matches[1]} h` : "";
    const minutes = matches[2] ? `${matches[2]} m` : "";

    // Combine hours and minutes
    return `${hours} ${minutes}`.trim();
  };

  const renderFlightOfferCard = (offer) => {
    console.log("inside flight offer card", offer);

    if (!offer || !offer.offer || offer.offer.length === 0) {
      return null; // Return null if offer or offer.offer is empty or undefined
    }
    const existingDate = new Date(offer.Date);
    const day = existingDate.getDate();
    const month = existingDate.toLocaleString("default", { month: "long" });
    const year = existingDate.getFullYear();

    // Construct the formatted date string
    const formattedDate = `${day} ${month} ${year}`;
    return (
      <div
        key={offer.Departure + offer.Destination}
        className="bg-black rounded-lg shadow-md p-4 mb-4 border border-[#424242]"
      >
        <div className="font-semibold text-sm mb-2">
          {offer.Departure} to {offer.Destination}
        </div>
        <div className="text-sm mb-2">{formattedDate}</div>
        <div className="block lg:hidden">
          <div className="flex gap-2 overflow-x-auto">
            {offer.offer.map((flight, index) => (
              <div key={index} className=" bg-[#2F2F2F] rounded-md p-2">
                <div className="flex flex-col justify-between">
                  <div className="mt-3">
                    <div className="flex">
                      <div className="font-light text-FontSize1020 mt-3">
                        {flight.carrier}
                      </div>
                      <div className="font-light text-FontSize1020 mt-3 ml-1">
                        {flight.aircraft}
                      </div>
                    </div>
                    {flight.Total_data.itineraries.map((itinerary) => (
                      <div key={itinerary.id}>
                        {itinerary.segments.map((segment, segmentIndex) => {
                          if (segmentIndex !== 0) return null; // Only render the first segment
                          return (
                            <div key={segment.id}>
                              <div className="flex items-center space-x-2">
                                <MdFlightTakeoff />
                                <div className="font-semibold">
                                  {formatTime(segment.departure.at)}
                                </div>
                              </div>
                              <div className="font-light text-FontSize1020 pl-6">
                                {offer.Departure}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    ))}
                    <div className="flex flex-col font-light text-FontSize1020 my-0">
                      {flight.duration}
                      {flight.flight_type === "Multi-stop" ? (
                        <text>-----o-----</text>
                      ) : (
                        <text>----------</text>
                      )}
                      {flight.flight_type === "Multi-stop" ? (
                        <div className="ml-3">{"1 Stop"}</div>
                      ) : (
                        <div className="ml-2">{"Non Stop"}</div>
                      )}
                    </div>
                    {flight.Total_data.itineraries.map((itinerary) => (
                      <div key={itinerary.id}>
                        {itinerary.segments.map(
                          (segment, segmentIndex, segmentsArray) => {
                            if (segmentIndex !== segmentsArray.length - 1)
                              return null; // Only render the last segment
                            return (
                              <div key={segment.id}>
                                <div className="flex items-center space-x-2">
                                  <MdFlightLand />
                                  <div className="font-semibold">
                                    {formatTime(segment.arrival.at)}
                                  </div>
                                </div>
                                <div className="font-light text-FontSize1020 mb-3 pl-6">
                                  {offer.Destination}
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>
                    ))}
                    <div className="mt-3 flex">
                      <text className="font-medium ml-1">₹</text>{" "}
                      {flight.price_total}
                    </div>
                  </div>
                </div>
                <div className="mt-2 flex justify-center">
                  <div
                    className="bg-blue-500 text-white font-semibold py-1 px-2 rounded-lg inline-block hover:cursor-pointer"
                    onClick={() => handleFlightConfirmPrice(flight.Total_data)}
                  >
                    {console.log("Total data: ", flight.Total_data)}
                    View
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="hidden lg:block">
          <div className="grid md:grid-rows-3 md:gap-4 gap-1">
            {offer.offer.map((flight, index) => (
              <div
                key={index}
                className="pt-2 mt-2 bg-[#2F2F2F] rounded-md md:p-4 p-2"
              >
                <div className="flex flex-col justify-between">
                  <div className="md:flex md:justify-between md:gap-6 mt-3">
                    <div className="flex">
                      <div className="font-light text-FontSize1020 mt-3">
                        {flight.carrier}
                      </div>
                      <div className="font-light text-FontSize1020 mt-3 ml-1">
                        {flight.aircraft}
                      </div>
                    </div>
                    {flight.Total_data.itineraries.map((itinerary) => (
                      <div key={itinerary.id}>
                        {itinerary.segments.map((segment, segmentIndex) => {
                          if (segmentIndex !== 0) return null; // Only render the first segment
                          return (
                            <div key={segment.id}>
                              <div className="flex items-center space-x-2">
                                <MdFlightTakeoff />
                                <div className="font-semibold">
                                  {formatTime(segment.departure.at)}
                                </div>
                              </div>
                              <div className="font-light text-FontSize1020 pl-6">
                                {offer.Departure}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    ))}
                    <div className="flex flex-col font-light text-FontSize1020 my-0">
                      {flight.duration}
                      {flight.flight_type === "Multi-stop" ? (
                        <text>-----o-----</text>
                      ) : (
                        <text>------------</text>
                      )}
                      {flight.flight_type === "Multi-stop" ? (
                        <div className="text-center">{"1 Stop"}</div>
                      ) : (
                        <div className="text-center">{"Non Stop"}</div>
                      )}
                    </div>
                    {flight.Total_data.itineraries.map((itinerary) => (
                      <div key={itinerary.id}>
                        {itinerary.segments.map(
                          (segment, segmentIndex, segmentsArray) => {
                            if (segmentIndex !== segmentsArray.length - 1)
                              return null; // Only render the last segment
                            return (
                              <div key={segment.id}>
                                <div className="flex items-center space-x-2">
                                  <MdFlightLand />
                                  <div className="font-semibold">
                                    {formatTime(segment.arrival.at)}
                                  </div>
                                </div>
                                <div className="font-light text-FontSize1020 mb-3 pl-6">
                                  {offer.Destination}
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>
                    ))}
                    <div className="mt-3 flex">
                      <text className="font-medium ml-1">₹</text>{" "}
                      {flight.price_total}
                    </div>
                    <div className="mt-2 flex justify-end">
                      <div
                        className="bg-blue-500 text-white font-semibold py-1 px-2 rounded-lg hover:cursor-pointer h-8"
                        onClick={() =>
                          handleFlightConfirmPrice(flight.Total_data)
                        }
                      >
                        {console.log("Total data: ", flight.Total_data)}
                        View
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const renderFlightOfferdetails = (offer) => {
    console.log("inside flight details card", offer);

    if (!offer) return null;

    const { flightOffers } = offer;

    return (
      <div>
        {/* Card for displaying the flight offer details */}
        {flightOffers.map((flightOffer) => (
          <div
            key={flightOffer.id}
            className="bg-black rounded-lg shadow-md p-4 mb-4 border border-[#424242] md:w-[500px]"
          >
            {flightOffer.itineraries.map((itinerary, index) => (
              <div key={index}>
                {itinerary.segments.map((segment, idx) => (
                  <div className="border-b-2 py-2">
                    <div className="text-sm">
                      {segment.carrierCode} | {segment.aircraft.code}
                    </div>
                    <div className="grid grid-cols-3 gap-4 ">
                      <div>
                        <div className="py-4">
                          <MdFlightTakeoff size={20} className="float-left" />
                        </div>
                        <div className="text-FontSize2431 mt-2">
                          {formatTime(segment.departure.at)}
                        </div>
                        <div className="text-FontSize1020">
                          {formatDate(segment.departure.at)}
                        </div>
                        <div className="text-FontSize1420 mt-2">
                          Terminal {segment.departure.terminal}
                        </div>
                        <div className="text-FontSize1214 mb-2">
                          {segment.departure.iataCode}
                        </div>
                      </div>

                      <div className="flex justify-center md:mt-8 mt-6 pb-24 md:pb-20">
                        <div className="text-sm border-b-2">
                          {formatDuration(segment.duration)}
                        </div>
                      </div>

                      <div className="items-end text-right">
                        <div className="py-4">
                          <MdFlightLand size={20} className="float-right" />{" "}
                        </div>
                        <div className="text-FontSize2431 mt-2">
                          {formatTime(segment.arrival.at)}
                        </div>
                        <div className="text-FontSize1020">
                          {formatDate(segment.arrival.at)}
                        </div>
                        <div className="text-FontSize1420 mt-2">
                          Terminal {segment.arrival.terminal}
                        </div>
                        <div className="text-FontSize1214 mb-2">
                          {segment.arrival.iataCode}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
            <div className="mt-4 text-FontSize1622">Price Details:</div>
            <div className="text-sm">
              Base Price: <text className="font-medium">₹</text>{" "}
              {flightOffer.price.base}
            </div>
            <div className="text-sm">
              Grand Total: <text className="font-medium">₹</text>{" "}
              {flightOffer.price.grandTotal}{" "}
            </div>
            {/* <div className="mt-4 text-FontSize1622">Taxes:</div>
            {flightOffer.travelerPricings.map((traveler, index) => (
              <div key={index} className="text-sm">
                Traveler {index + 1} Taxes:{" "}
                {traveler.price.taxes
                  .map(
                    (tax) =>
                      `${tax.code}: ${tax.amount} ${flightOffer.price.currency}`
                  )
                  .join(", ")}
              </div>
            ))} */}
            <div>
              <div className="mt-4 text-FontSize1622">Baggage Allowance:</div>
              <div className="text-sm">
                {`${flightOffer.travelerPricings[0].fareDetailsBySegment[0].includedCheckedBags.weight} ${flightOffer.travelerPricings[0].fareDetailsBySegment[0].includedCheckedBags.weightUnit}`}
              </div>
            </div>

            <div className="mt-2 flex justify-end">
              <div
                className="bg-blue-500 text-white font-semibold py-1 px-2 rounded-lg inline-block hover:cursor-pointer"
                // onClick={() => handleFlightOrder(flightOffer)}
                onClick={
                  () =>
                    localStorage.getItem("Token")
                      ? (setGlobalFlightOffer(flightOffer),
                        // setIsAddTravelerModalOpen(true)
                        setIsSavedTravellerModalOpen(true))
                      : setShowLogin(true)
                  // navigate("/login")
                }
              >
                Confirm
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderFlightTicketCard = ({ flightData }) => {
    console.log(" inside flihht order data,", flightOrderData);

    const { travelers, flightOffers } = flightOrderData;

    return (
      <div className="bg-black rounded-lg shadow-md mb-4 border-[#424242]">
        <div className="font-bold text-lg h-14 bg-blue-600 rounded-t-lg shadow-md flex items-center justify-center text-white">
          Flight Ticket
        </div>
        <div className="p-4">
          {/* Render traveler details */}
          <div className="grid grid-cols-4 justify-items-center gap-4">
            {/* Passenger Name */}
            <div className="col-span-1">
              {travelers.map((traveler) => (
                <div key={traveler.id} className="mb-4">
                  <div className="text-FontSize1214">
                    NAME OF PASSENGER
                    <div className="text-FontSize1824 mt-1">{`${traveler.name.firstName} ${traveler.name.lastName}`}</div>
                  </div>
                </div>
              ))}
            </div>
            {/* Flight Details */}
            <div className="col-span-1">
              {flightOffers.map((data) => (
                <div key={data.id} className="mb-4">
                  {data.itineraries.map((itinerary, index) => (
                    <div key={index} className="mb-4">
                      {itinerary.segments.length > 0 && (
                        <div>
                          <div className="text-FontSize1214">
                            FLIGHT
                            <div className="text-FontSize1824 mt-1">
                              {itinerary.segments[0].carrierCode}{" "}
                              {itinerary.segments[0].aircraft.code}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              ))}
            </div>
            {/* Date Details */}
            <div className="col-span-1">
              {flightOffers.map((data) => (
                <div key={data.id} className="mb-4">
                  {data.itineraries.map((itinerary, index) => (
                    <div key={index} className="mb-4">
                      {itinerary.segments.length > 0 && (
                        <div>
                          <div className="text-FontSize1214">
                            DATE
                            <div className="text-FontSize1824 mt-1">
                              {formatDate(itinerary.segments[0].departure.at)}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              ))}
            </div>
            {/* Seat Details */}
            <div className="col-span-1">
              {flightOffers.map((data) => (
                <div key={data.id} className="mb-4">
                  {data.itineraries.map((itinerary, index) => (
                    <div key={index} className="mb-4">
                      {itinerary.segments.length > 0 && (
                        <div>
                          <div className="text-FontSize1214">
                            SEAT
                            <div className="text-FontSize1824 mt-1">{"7A"}</div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>

          {/* Render flight details */}
          {/* Render flight details */}
          <div className="mb-4 pt-6">
            {flightOffers.map((flightOffer) => (
              <div key={flightOffer.id}>
                {flightOffer.itineraries.map((itinerary, index) => (
                  <div
                    key={index}
                    className="flex justify-between md:px-10 px-4"
                  >
                    <div>
                      {itinerary.segments.map((segment, idx) => (
                        <div key={idx}>
                          <div className="text-FontSize3242">
                            {idx === 0
                              ? itinerary.segments[0].departure.iataCode
                              : null}
                          </div>
                        </div>
                      ))}
                    </div>
                    <div>
                      <FaPlane size={50} />
                    </div>
                    <div>
                      {itinerary.segments.map((segment, idy) => (
                        <div className="text-FontSize3242">
                          {idy === itinerary.segments.length - 1
                            ? segment.arrival.iataCode
                            : null}
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
                <div class="flex justify-center mt-8">
                  <div class="w-1/2 mx-2 flex justify-center">
                    <div class="text-FontSize1214">
                      GATE
                      <div class="text-FontSize1824 mt-1">{"D 12"}</div>
                    </div>
                  </div>
                  <div class="w-1/2 mx-2 flex justify-center">
                    <div class="text-FontSize1214">
                      BOARDING TIME
                      <div class="text-FontSize1824 mt-1">{"07:30"}</div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const combinedMessages = [];
  const maxLength = Math.max(messages.length, AIMessages.length);

  // Iterate through messages and AIMessages to combine them
  // Iterate through messages and AIMessages to combine them
  for (let i = 0; i < maxLength; i++) {
    if (messages[i]) {
      combinedMessages.push({ type: "user", message: messages[i] });
    }
    if (AIMessages[i]) {
      if (AIMessages[i].category === "user_message") {
        combinedMessages.push({
          type: "user",
          message: AIMessages[i].message,
          msgType: "user_message",
        });
      }
      if (AIMessages[i].category === "message") {
        combinedMessages.push({
          type: "AI",
          message: AIMessages[i].message,
          msgType: "simple_message",
        });
      } else if (AIMessages[i].category === "AI_FlightOffer") {
        combinedMessages.push({
          type: "AI",
          message: AIMessages[i].message,
          msgType: "flight_offers_search",
        });
      } else if (AIMessages[i].category === "AI_FlightOfferPrice") {
        combinedMessages.push({
          type: "AI",
          message: AIMessages[i].message,
          msgType: "flight-offers-pricing",
        });
      } else if (AIMessages[i].category === "AI_FlightOrder") {
        combinedMessages.push({
          type: "AI",
          message: AIMessages[i].message,
          msgType: "flight-order",
        });
      } else if (AIMessages[i].category === "audio_message") {
        console.log("ggggg:", typeof AIMessages[i].message);
        if (typeof AIMessages[i].message === "object") {
          combinedMessages.push({
            type: "user",
            message: AIMessages[i].message.user,
            msgType: "audio_message",
          });
          combinedMessages.push({
            type: "AI",
            message: AIMessages[i].message.assistant,
            msgType: "audio_message",
          });
        } else {
          combinedMessages.push({
            type: "user",
            message: AIMessages[i].message.user,
            msgType: "audio_message",
          });
          combinedMessages.push({
            type: "AI",
            message: AIMessages[i].message.assistant,
            msgType: "flight_offers_search",
          });
        }
      } else if (AIMessages[i].category === "audio_AI_FlightOffer") {
        combinedMessages.push({
          type: "user",
          message: AIMessages[i].message.user,
          msgType: "audio_message",
        });
        combinedMessages.push({
          type: "AI",
          message: AIMessages[i].message.assistant,
          msgType: "flight_offers_search",
        });
      }
    }
  }

  useEffect(() => {
    scrollToBottom();
  }, [combinedMessages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  console.log("combined messages: ", combinedMessages);
  console.log("token: ", localStorage.getItem("Token"));

  return (
    <div className="flex flex-col h-screen bg-[#212121]">
      <Header clearMessages={clearMessages} />

      <div className="flex-1 overflow-y-auto bg-[#212121]">
        {/* Rendering messages */}
        {combinedMessages.length > 0 ? (
          <div className="pt-4 pb-[80px] px-1 flex flex-col">
            {combinedMessages.map((item, index) => (
              <div
                key={index}
                className={`bg-${
                  item.type === "user" ? "[#2F2F2F]" : ""
                } me-1 ms-1 py-2 mb-2 rounded-full ${
                  item.type === "user"
                    ? "self-end text-white"
                    : "self-start text-white"
                }`}
                style={{
                  textAlign: item.type === "user" ? "right" : "left",
                  maxWidth: "90%",
                }}
              >
                <div className="pe-3 ps-3">
                  {item.type === "user" && item.message}
                </div>
                {item.type === "AI" &&
                  item.message &&
                  (item.msgType === "flight_offers_search" ||
                    item.msgType === "flight-offers-pricing") &&
                  renderFlightOfferCard(item.message)}
                {item.type === "AI" &&
                  item.message &&
                  item.msgType === "flight-offers-pricing" &&
                  typeof item.message === "object" &&
                  renderFlightOfferdetails(globalFlightDetails)}
                {item.type === "AI" &&
                  item.message &&
                  item.msgType === "flight-order" &&
                  renderFlightTicketCard(flightOrderData)}
                {item.type === "AI" &&
                  item.message &&
                  (item.msgType !== "flight_offers_search" ||
                    item.msgType !== "flight-offers-pricing") &&
                  typeof item.message === "string" &&
                  item.message}
              </div>
            ))}
            {/** Skeleton */}
            {(showSkeleton || histSkeleton) && <DotWaveLoader />}
            <div ref={messagesEndRef} />
          </div>
        ) : !histSkeleton && !showSkeleton ? (
          <div className="flex flex-col items-center justify-center h-full w-full">
            <div className="text-center px-4 md:text-2xl text-xl text-white">
              Hello! I'm an AI assistant designed to facilitate Flight bookings.
            </div>
            <div className="flex flex-col justify-around mt-4 gap-4">
              <div
                className="hover:bg-[#2F2F2F] border border-[#2F2F2F] text-white flex flex-col items-center justify-center px-4 py-2 mx-2 rounded-full cursor-pointer"
                onClick={() =>
                  handleTransportClick(
                    `Book a Flight from Bengaluru to Chennai on ${date1}`
                  )
                }
              >
                Book a Flight from Bengaluru to Chennai on {date1}
              </div>
              <div
                className="hover:bg-[#2F2F2F] border border-[#2F2F2F] text-white flex flex-col items-center justify-center px-4 py-2 rounded-full mx-2 cursor-pointer"
                onClick={() =>
                  handleTransportClick(
                    `Show flights from Chennai to Delhi on ${date2}`
                  )
                }
              >
                Show flights from Chennai to Delhi on {date2}
              </div>
              <div
                className="hover:bg-[#2F2F2F] border border-[#2F2F2F] text-white flex flex-col items-center justify-center px-4 py-2 rounded-full mx-2 cursor-pointer "
                onClick={() =>
                  handleTransportClick(`Flights from Delhi to Goa on ${date3}`)
                }
              >
                Flights from Delhi to Goa on {date3}
              </div>
            </div>
          </div>
        ) : (
          <DotWaveLoader />
        )}
      </div>
      <Footer
        addMessage={addMessage}
        addAIMessage={addAIMessage}
        addMsgType={addMsgType}
        loading={loading}
        setLoading={setLoading}
        flightOffer={flightOffer}
        addFlightOffer={addFlightOffer}
      />
      {loading && (
        <div className="fixed top-0 left-0 h-full w-full flex justify-center items-center z-50">
          <div className="absolute inset-0 bg-black opacity-20"></div>
          <div className="relative">
            <div className="animate-spin rounded-full h-8 w-8 border-t-4 border-b-4 border-white"></div>
          </div>
        </div>
      )}
      {isAddTravelerModalOpen && (
        <AddTraveller
          setLoading={setLoading}
          addAIMessage={addAIMessage}
          addMsgType={addMsgType}
        />
      )}
      {/* {isSavedTravellerModalOpen && ( */}
      <SavedTravellers
        setLoading={setLoading}
        addAIMessage={addAIMessage}
        addMsgType={addMsgType}
      />
      {/* )} */}
      {showLogin && <Login />}
      {showSignup && <SignUp />}
    </div>
  );
};

export default ChatScreen;
