import React, { useEffect, useState, useRef, useContext } from "react";
import {
  FaRegPaperPlane as SendIcon,
  FaMicrophone as MicIcon,
} from "react-icons/fa";
import { RiVoiceRecognitionFill as RecognitionIcon } from "react-icons/ri";
import chat from "../services/Chat";
import { Tooltip } from "react-tooltip";
import { FlightContext } from "../utils/FlightContext";

const Footer = ({
  addMessage,
  addAIMessage,
  addMsgType,
  loading,
  setLoading,
  addFlightOffer,
}) => {
  const { showSkeleton, setShowSkeleton } = useContext(FlightContext);
  const [inputValue, setInputValue] = useState("");
  const [uploadAudio, setUploadAudio] = useState(false);
  const [recording, setRecording] = useState(false);
  const [audioChunks, setAudioChunks] = useState([]);
  const audioRecorderRef = useRef(null);
  const recordingTimeoutRef = useRef(null);

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        audioRecorderRef.current = new MediaRecorder(stream);
        audioRecorderRef.current.addEventListener("dataavailable", (e) => {
          setAudioChunks((prevAudioChunks) => [...prevAudioChunks, e.data]);
        });
      })
      .catch((err) => {
        console.error("Error: " + err);
      });
  }, []);

  const startRecording = () => {
    console.log("Starting recording");
    setAudioChunks([]);
    setRecording(true);
    audioRecorderRef.current.start();
  };

  const stopRecording = () => {
    console.log("Stop recording");
    audioRecorderRef.current.stop();
    setRecording(false);
    setUploadAudio(true);
  };

  const handleStart = () => {
    startRecording();
    recordingTimeoutRef.current = setTimeout(() => {
      stopRecording();
    }, 5000); // Automatically stop recording after 5 seconds
  };

  const handleEnd = () => {
    clearTimeout(recordingTimeoutRef.current);
    stopRecording();
  };

  const handleUploadButtonClick = async () => {
    const blobObj = new Blob(audioChunks, { type: "audio/wav" });
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
    const day = ("0" + currentDate.getDate()).slice(-2);
    const hour = ("0" + currentDate.getHours()).slice(-2);
    const minute = ("0" + currentDate.getMinutes()).slice(-2);
    const second = ("0" + currentDate.getSeconds()).slice(-2);
    const millisecond = ("0" + currentDate.getMilliseconds()).slice(-2);
    const fileName = `recorded_audio_${year}_${month}_${day}_${hour}_${minute}_${second}_${millisecond}.wav`;

    const formData = new FormData();
    formData.append("audio", blobObj, fileName);

    try {
      setShowSkeleton(true);
      const response = await chat.sendAudioMessage(formData);
      if (response) {
        if (response) {
          setShowSkeleton(false);
        }
        if (response.message.type === "flight_offers_search") {
          // if (response.message.message.assistant.offer.length === 0) {
          //   setShowSkeleton(true);
          //   return null;
          // }
          addFlightOffer(response.message.message, "AI_FlightOffer");
          addAIMessage({
            message: response.message.message,
            category: "audio_AI_FlightOffer",
          });
          addMsgType(response.message.type);
        } else {
          addAIMessage({
            message: response.message.message,
            category: "audio_message",
          });
          addMsgType(response.message.type);
        }
        console.log("Audio message sent successfully");
      }
    } catch (error) {
      setShowSkeleton(false);
      console.error("Error sending audio message:", error);
    }
    setShowSkeleton(false);
  };

  useEffect(() => {
    if (uploadAudio && audioChunks.length > 0) {
      console.log("audioChunks", audioChunks);
      handleUploadButtonClick();
    }
  }, [uploadAudio, audioChunks]);

  const sendMessage = async () => {
    if (inputValue.trim() !== "") {
      // addMessage(inputValue);
      addAIMessage({
        message: inputValue,
        category: "user_message",
      });
      setInputValue("");
      setShowSkeleton(true);
      try {
        const response = await chat.sendChatMessage(inputValue);
        if (response) {
          setShowSkeleton(false);
        }
        if (response.message.type === "flight_offers_search") {
          if (response.message.message[0] === "No Flight") {
            addAIMessage({
              message: response.message.message[0],
              category: "message",
            });
            addMsgType("message");
          } else {
            addFlightOffer(response.message.message, "AI_FlightOffer");
            addAIMessage({
              message: response.message.message,
              category: "AI_FlightOffer",
            });
            addMsgType(response.message.type);
          }
          if (response.message.message.offer?.length === 0) {
            setShowSkeleton(true);
            return null;
          }
        } else {
          addAIMessage({
            message: response.message.message,
            category: "message",
          });
          addMsgType(response.message.type);
        }
      } catch (error) {
        setShowSkeleton(false);
        console.error("Error sending message here in footer:", error);
      }
      setShowSkeleton(false);
      setInputValue("");
    }
  };

  return (
    <div className="md:px-32 p-4">
      <div className="flex items-center justify-between bg-[#212121] text-white pb-4 lg:pb-8">
        <input
          type="text"
          placeholder="Type your message"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              sendMessage();
            }
          }}
          disabled={showSkeleton}
          className="flex-1 mr-2 p-2 pl-6 rounded-full focus:outline-none focus:border-[#9b9b9b] bg-[#2F2F2F] h-12"
        />
        <button
          className="flex items-center justify-center w-12 h-12 rounded-full lg:bg-[#9b9b9b] bg-white text-white hover:bg-white focus:outline-none"
          onClick={sendMessage}
        >
          <SendIcon size={22} color="#2F2F2F" />
        </button>
        <button
          className={`flex items-center justify-center w-12 h-12 rounded-full text-white ${
            recording ? "hover:bg-red-500" : "hover:bg-[#9b9b9b]"
          } focus:outline-none ml-2 ${
            recording ? "bg-red-500" : ""
          } hide-on-mobile`}
          onMouseDown={startRecording}
          onMouseUp={stopRecording}
          onTouchStart={handleStart}
          onTouchEnd={handleEnd}
          onTouchCancel={handleEnd}
        >
          {recording ? (
            <RecognitionIcon size={22} />
          ) : (
            <MicIcon
              size={22}
              data-tooltip-id="my-tooltip"
              data-tooltip-content="Press and Hold to Record your Voice!"
              data-tooltip-place="top"
              color="#fff"
            />
          )}
        </button>

        <Tooltip id="my-tooltip" />
      </div>
    </div>
  );
};

export default Footer;
