import React, { useContext, useState, useEffect, useRef } from "react";
import { FlightContext } from "../../utils/FlightContext";
import { addTraveller } from "../../services/Travellers";
import { MdArrowBackIos, MdClose, MdCalendarToday } from "react-icons/md";
import { FaSpinner } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const AddTraveller = ({ addAIMessage, addMsgType }) => {
  const {
    isAddTravelerModalOpen,
    setIsAddTravelerModalOpen,
    setTravellers,
    setIsSavedTravellerModalOpen,
  } = useContext(FlightContext);

  const [loading, setLoading] = useState(false);
  const [traveller, setTraveller] = useState({
    first_name: "",
    last_name: "",
    date_of_birth: "",
    gender: "",
    email: "",
    phone_number: "",
    country_code: "",
    passport_number: "",
    birth_place: "",
    issuance_location: "",
    issuance_date: "",
    expiry_date: "",
    issuance_country: "",
    validity_country: "",
    nationality_country: "",
    holder: "",
    document_required: localStorage.getItem("docReq") === "true",
  });

  const wrapperRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        handleClose();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "country_code") {
      if (/^\d*$/.test(value)) {
        setTraveller((prevTraveller) => ({
          ...prevTraveller,
          [name]: value,
        }));
      }
    } else {
      setTraveller((prevTraveller) => ({
        ...prevTraveller,
        [name]: value,
      }));
    }
  };

  const handleDateChange = (name, date) => {
    const formattedDate = date.toISOString().split("T")[0]; // This will give YYYY-MM-DD
    setTraveller((prevTraveller) => ({
      ...prevTraveller,
      [name]: formattedDate,
    }));
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const newTraveller = {
      ...traveller,
      gender: traveller.gender.toUpperCase(),
    };
    try {
      const response = await addTraveller(newTraveller);
      console.log("trav resp: ", response);
    } catch (error) {
      console.error("Error: ", error);
    }
    setTravellers((prevTravellers) => [...prevTravellers, newTraveller]);

    // Clear the form after submission
    setTraveller({
      first_name: "",
      last_name: "",
      date_of_birth: "",
      gender: "",
      email: "",
      phone_number: "",
      country_code: "",
      passport_number: "",
      birth_place: "",
      issuance_location: "",
      issuance_date: "",
      expiry_date: "",
      issuance_country: "",
      validity_country: "",
      nationality_country: "",
      holder: "",
    });
    setLoading(false);
    handleClose();
  };

  const handleClose = () => {
    setIsAddTravelerModalOpen(false);
    setIsSavedTravellerModalOpen(true);
  };

  const domesticFlight = localStorage.getItem("isDomestic") === "true";
  const today = new Date().toISOString().split("T")[0];

  return (
    <>
      {isAddTravelerModalOpen && (
        <div className="fixed top-0 left-0 w-full h-full bg-white opacity-50 z-40"></div>
      )}
      <div
        ref={wrapperRef}
        className="fixed top-0 right-0 h-full md:px-20 md:py-10 p-10 md:w-[600px] w-full bg-[#212121] overflow-hidden shadow-lg transform translate-x-0 transition-transform duration-600 ease-in-out z-50"
      >
        <div className="bg-[#212121] rounded-lg shadow-md max-w-md h-full flex flex-col">
          <button
            type="button"
            onClick={handleClose}
            className="absolute top-4 right-4"
          >
            <MdClose size={24} color="white" />
          </button>
          <div className="flex-1 overflow-auto p-4">
            <form onSubmit={handleSubmit}>
              <div className="grid grid-cols-2 gap-2">
                {[
                  { label: "First Name", id: "first_name" },
                  { label: "Last Name", id: "last_name" },
                  {
                    label: "Date of Birth",
                    id: "date_of_birth",
                    type: "date",
                    max: today,
                  },
                  {
                    label: "Gender",
                    id: "gender",
                    type: "select",
                    options: ["MALE", "FEMALE", "OTHER"],
                  },
                  { label: "Email", id: "email", type: "email" },
                  { label: "Phone Number", id: "phone_number", type: "tel" },
                  { label: "Country Code", id: "country_code" },
                  !domesticFlight && {
                    label: "Passport Number",
                    id: "passport_number",
                  },
                  !domesticFlight && {
                    label: "Birth Place",
                    id: "birth_place",
                  },
                  !domesticFlight && {
                    label: "Issuance Location",
                    id: "issuance_location",
                  },
                  !domesticFlight && {
                    label: "Issuance Date",
                    id: "issuance_date",
                    type: "date",
                  },
                  !domesticFlight && {
                    label: "Expiry Date",
                    id: "expiry_date",
                    type: "date",
                  },
                  !domesticFlight && {
                    label: "Issuance Country",
                    id: "issuance_country",
                  },
                  !domesticFlight && {
                    label: "Validity Country",
                    id: "validity_country",
                  },
                  !domesticFlight && {
                    label: "Nationality Country",
                    id: "nationality_country",
                  },
                  !domesticFlight && { label: "Holder", id: "holder" },
                ].map(
                  (field) =>
                    field && (
                      <div key={field.id} className="mb-3">
                        <label
                          className="block text-white text-sm font-bold mb-2"
                          htmlFor={field.id}
                        >
                          {field.label}
                        </label>
                        {field.type === "select" ? (
                          <select
                            id={field.id}
                            name={field.id}
                            value={traveller[field.id]}
                            onChange={handleChange}
                            className="shadow-sm appearance-none border border-gray-300 rounded w-full py-3 px-4 leading-tight focus:outline-none focus:ring-2 focus:ring-white bg-white"
                            required
                          >
                            <option value="">
                              Select {field.label.toLowerCase()}
                            </option>
                            {field.options.map((option) => (
                              <option key={option} value={option.toLowerCase()}>
                                {option}
                              </option>
                            ))}
                          </select>
                        ) : (
                          <div className="relative">
                            {field.type === "date" ? (
                              <DatePicker
                                selected={traveller[field.id]}
                                onChange={(date) =>
                                  handleDateChange(field.id, date)
                                }
                                maxDate={
                                  field.id === "date_of_birth"
                                    ? new Date()
                                    : undefined
                                }
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                placeholderText="dd/mm/yyyy"
                                dateFormat="dd/MM/yyyy"
                                className="shadow-sm appearance-none border border-gray-300 rounded w-full py-3 px-4 text-balck leading-tight focus:outline-none focus:ring-2 focus:ring-white bg-white"
                                required
                              />
                            ) : (
                              <input
                                type={field.type || "text"}
                                id={field.id}
                                name={field.id}
                                value={traveller[field.id]}
                                onChange={handleChange}
                                className="shadow-sm appearance-none border border-gray-300 rounded w-full py-3 px-4 text-black leading-tight focus:outline-none focus:ring-2 focus:ring-white bg-white"
                                placeholder={`Enter ${field.label.toLowerCase()}`}
                                required
                              />
                            )}
                            {field.type === "date" && (
                              <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                                <MdCalendarToday className="text-gray-400 cursor-pointer" />
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )
                )}
              </div>
              <div className="pb-10"></div>{" "}
              {/* Add padding to prevent overlap with the button */}
            </form>
          </div>
          <div className="fixed bottom-0 left-0 w-full md:w-[600px] p-4 bg-[#212121] flex justify-center">
            <button
              type="submit"
              onClick={handleSubmit}
              disabled={loading}
              className="w-72 bg-[#9b9b9b] hover:bg-white text-white font-bold py-2 px-6 rounded focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50 hover:text-[#2F2F2F]"
            >
              Add Traveller
            </button>
            {loading && <FaSpinner className="ml-4 animate-spin text-white" />}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddTraveller;
